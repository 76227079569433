import React, { useEffect, useState } from "react";
import HeaderTopBar from "./HeaderTopBar";
import { FaFileSignature } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { useFormik } from "formik";

export default function AddministrationDrc() {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [healthZoneList, setHealthZoneList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(null);
  const [name, setName] = useState(null);

  let dropDownData;
  if (name === "LGA") {
    dropDownData = stateList;
  } else if (name === "Provience") {
    dropDownData = countryList;
  }else if (name === "Community") {
    dropDownData = healthZoneList;
  } else if (name === "Ward" || name === "HFN") {
    dropDownData = healthZoneList;
  }

  console.log("dropdown---data", dropDownData);

  let dropDownValue;
  if (name === "State") {
    dropDownValue = isUpdate?.region;
  } else if (name === "LGA") {
    dropDownValue = isUpdate?.province;
  } else if (name === "Provience") {
    dropDownValue = isUpdate?.country;
  } else if (name === "Division") {
    dropDownValue = isUpdate?.province;
  } else if (name === "District") {
    dropDownValue = isUpdate?.division;
  }else if (name === "Community") {
    dropDownValue = isUpdate?.district;
  } else if (name === "Ward" || name === "HFN") {
    dropDownValue = isUpdate?.district;
  }

  console.log("Dropdown---Value", dropDownValue);
  console.log("Is----update", isUpdate);

  //Pagination functionality
  const [currentStatePage, setCurrentStatePage] = useState(1);
  const [currentCountryPage, setCurrentCountryPage] = useState(1);

  const [currentLgaPage, setCurrentLgaPage] = useState(1);
  const [currentWardPage, setCurrentWardPage] = useState(1);
  const [currentCommunityPage, setCurrentCommunityPage] = useState(1);
  const itemsPerPage = 4;

  const paginatedCountryData = countryList.slice(
    (currentCountryPage - 1) * itemsPerPage,
    currentCountryPage * itemsPerPage
  );
  const paginatedStateData = stateList.slice(
    (currentStatePage - 1) * itemsPerPage,
    currentStatePage * itemsPerPage
  );
  const paginatedLgaData = healthZoneList.slice(
    (currentLgaPage - 1) * itemsPerPage,
    currentLgaPage * itemsPerPage
  );
  const paginatedWardData = wardList.slice(
    (currentWardPage - 1) * itemsPerPage,
    currentWardPage * itemsPerPage
  );
  const paginateCommunityData = communityList.slice(
    (currentCommunityPage - 1) * itemsPerPage,
    currentCommunityPage * itemsPerPage
  );

  const totalStatePages = Math.ceil(stateList?.length / itemsPerPage);
  const totalLgaPages = Math.ceil(healthZoneList?.length / itemsPerPage);
  const totalWardPages = Math.ceil(wardList?.length / itemsPerPage);
  const totalCommunityPages = Math.ceil(communityList?.length / itemsPerPage);

  const handleStateChange = (newPage) => {
    setCurrentStatePage(newPage);
  };
  const handleLgaChange = (newPage) => {
    setCurrentLgaPage(newPage);
  };
  const handleWardChange = (newPage) => {
    setCurrentWardPage(newPage);
  };
  const handleCommunityChange = (newPage) => {
    setCurrentCommunityPage(newPage);
  };

  console.log("Dropdown data", dropDownData);
  console.log("Dropdown value", dropDownValue);

  const updateInitialValue = {
    name: isUpdate ? isUpdate?.name : "",
    id: isUpdate ? dropDownData?.find((item) => item.id === dropDownValue) : 0,
    country: paginatedCountryData?.map((item) => {
      return {
        ...item,
        code: item?.id,
        name: item?.name,
      };
    }),

    state: paginatedStateData?.map((item) => {
      return {
        ...item,
        code: item?.id,
        name: item?.name,
      };
    }),
    lga: paginatedLgaData?.map((item) => {
      return {
        ...item,
        code: item?.id,
        name: item?.name,
      };
    }),
    ward: paginatedWardData?.map((item) => {
      return {
        ...item,
        code: item?.id,
        name: item?.name,
      };
    }),
    community: paginateCommunityData?.map((item) => {
      return {
        ...item,
        code: item?.id,
        name: item?.name,
      };
    }),
  };
  //  ---- Start Formik Object -----
  const formik = useFormik({
    initialValues: updateInitialValue,
    onSubmit: (values) => {
      //   handleSubmit(values);
    },
    enableReinitialize: true,
    validateOnChange: false,
  });

  console.log("Formik->Values",formik.values);
  const initialValue = {
    stateID: 0,
    provienceID:0,
    healthZoneID:0
  };
  //  ---- Start Dropdown Formik Object -----
  const dropDownFormik = useFormik({
    initialValues: initialValue,
    onSubmit: (values) => {},
    enableReinitialize: true,
    validateOnChange: false,
  });

  //Api's Url
  const countryUrl = `${process.env.REACT_APP_MAIN_URL}/basic/country`;
  const stateUrl = `${process.env.REACT_APP_MAIN_URL}/users/states/`;
  const wardUrl = `${process.env.REACT_APP_MAIN_URL}/basic/get_ward`;
  const communityUrl = `${process.env.REACT_APP_MAIN_URL}/basic/get_community`;
  

  const handleEditClick = async () => {
    if (name) {
      const allValue = {
        name: formik.values?.name,
        ...(name === "State"
          ? {
              country: 8,
            }
          : {}),
        ...(name === "LGA"
          ? {
            province: Number(formik.values?.id?.id) || Number(formik.values?.id),
            }
          : {}),
        ...(name === "Ward"
          ? { district: Number(formik.values?.id?.id) || Number(formik.values?.id) }
          : {}),
          ...(name === "Community"
          ? { district: Number(formik.values?.id?.id) || Number(formik.values?.id),flow:"provience" }
          : {}),
      };
      const endpoints = {
        State: {
          url: `${process.env.REACT_APP_MAIN_URL}/basic/edit_province_for_admin/${isUpdate?.id}`,
          onSuccess: () => {
            getState();
          },
        },
        LGA: {
          url: `${process.env.REACT_APP_MAIN_URL}/basic/edit_district_for_admin/${isUpdate?.id}`,
          onSuccess: () => {
            getHealthZone(dropDownFormik?.values?.provienceID);
          },
        },
        Ward: {
          url: `${process.env.REACT_APP_MAIN_URL}/basic/admin_ward/${isUpdate?.district}/`,
          onSuccess: () => {
            getWard(dropDownFormik?.values?.healthZoneID);
          },
        },
        Community: {
          url: `${process.env.REACT_APP_MAIN_URL}/basic/admin_community/${isUpdate?.district}/`,
          onSuccess: () => {
            getCommunity(dropDownFormik?.values?.districtID);
          },
        },
      };
      const { url, onSuccess } = endpoints[name];
      if (url) {
        try {
          setLoading(true);
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await axios.put(url, allValue, config);
          if (response.status === 200) {
            setLoading(false);
            toast.success("Update Successfully");
            setName(null);
            formik.resetForm();
            setIsUpdate(null);
            onSuccess();
          }
        } catch (error) {
          setLoading(false);
          toast.error("Something went wrong");
        }
      }
      setShowModal(false);
    }
  };

  //Get Region
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Get Dashboard data
  const getState = async () => {
    setLoading(true);
    const allValue = {
      name: "",
      flow: "provience",
      country_id: 8,
    };
    try {
      setLoading(true);
      const response = await axios.post(countryUrl, allValue, axiosConfig);
      if (response.status === 201 || response.status === 200) {
        setLoading(false);
        setStateList(response?.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  //Get LGA data
  const getCountry = () => {
    setLoading(true);
    axios
      .get(countryUrl, axiosConfig)
      .then((response) => {
        setCountryList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };
  // const getHealthZone = (id) => {
  //   const lgaUrl = `${process.env.REACT_APP_MAIN_URL}/basic/get_health_districts_by_region/${id}`;
  //   setLoading(true);
  //   axios
  //     .get(lgaUrl, axiosConfig)
  //     .then((response) => {
  //       setHealthZoneList(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       toast.error("Something went wrong in LGA");
  //       setLoading(false);
  //     });
  // };
  const getWard = async (id) => {
    setLoading(true);
    const allValue = {
      name: "",
      flow: "provience",
      id: id,
    };
    try {
      setLoading(true);
      const response = await axios.post(wardUrl, allValue, axiosConfig);
      if (response.status === 201 || response.status === 200) {
        setLoading(false);
        setWardList(response?.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  const getHealthZone = (id) => {
    const healthZoneeUrl = `${process.env.REACT_APP_MAIN_URL}/basic/get_health_zones_by_provience/${id}`;
    setLoading(true);
    axios
      .get(healthZoneeUrl, axiosConfig)
      .then((response) => {
        setHealthZoneList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };

  //Get Ward
  // const getWard = () => {
  //   setLoading(true);
  //   axios
  //     .get(wardUrl, axiosConfig)
  //     .then((response) => {
  //       setWardList(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       toast.error("Something went wrong in ward");
  //       setLoading(false);
  //     });
  // };
 
  // const getCommunity = () => {
  //   setLoading(true);
  //   axios
  //     .get(wardUrl, axiosConfig)
  //     .then((response) => {
  //       setCommunityList(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       toast.error("Something went wrong in ward");
  //       setLoading(false);
  //     });
  // };
  const getCommunity = async (id) => {
    setLoading(true);
    const allValue = {
      name: "",
      flow: "provience",
      id: id,
    };
    try {
      setLoading(true);
      const response = await axios.post(communityUrl, allValue, axiosConfig);
      if (response.status === 201 || response.status === 200) {
        setLoading(false);
        setCommunityList(response?.data);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getState();
    // getWard();
    getCountry();
  }, []);
  console.log("Dropdown formik values",dropDownFormik?.values?.countryID)
  useEffect(() => {
    if (dropDownFormik?.values?.provienceID) {
      getHealthZone(dropDownFormik?.values?.provienceID);
    }
  }, [dropDownFormik?.values?.provienceID]);
  useEffect(() => {
    if (dropDownFormik?.values?.healthZoneID) {
      getWard(dropDownFormik?.values?.healthZoneID);
    }
  }, [dropDownFormik?.values?.healthZoneID]);
  useEffect(() => {
    if (dropDownFormik?.values?.healthZoneID) {
      getCommunity(dropDownFormik?.values?.healthZoneID);
    }
  }, [dropDownFormik?.values?.healthZoneID]);

  const onStateChange = (e, i, fieldName) => {
    if (e.target?.value) {
      return formik.setFieldValue(`state[${i}].${fieldName}`, e.target.value);
    }
    formik.setFieldValue(`state[${i}].${fieldName}`, e.value);
  };
  const onLgaChange = (e, i, fieldName) => {
    if (e.target?.value) {
      return formik.setFieldValue(`lga[${i}].${fieldName}`, e.target.value);
    }
    formik.setFieldValue(`lga[${i}].${fieldName}`, e.value);
  };
  const onWardChange = (e, i, fieldName) => {
    if (e.target?.value) {
      return formik.setFieldValue(`ward[${i}].${fieldName}`, e.target.value);
    }
    formik.setFieldValue(`ward[${i}].${fieldName}`, e.value);
  };
  const onCommunityChange = (e, i, fieldName) => {
    if (e.target?.value) {
      return formik.setFieldValue(`community[${i}].${fieldName}`, e.target.value);
    }
    formik.setFieldValue(`community[${i}].${fieldName}`, e.value);
  };

  //Delete Functionality

  const handleDeleteState = (valId) => {
    const deleteUrl = `${process.env.REACT_APP_MAIN_URL}/basic/edit_province_for_admin/${valId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .delete(deleteUrl, config)
      .then((response) => {
        if (response.status === 200) {
          toast.success("State Deleted successfully");
          getState();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  const handleDeleteLga = (valId) => {
    const deleteUrl = `${process.env.REACT_APP_MAIN_URL}/basic/edit_district_for_admin/${valId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .delete(deleteUrl, config)
      .then((response) => {
        if (response.status === 200) {
          toast.success("LGA Deleted successfully");
          getHealthZone(dropDownFormik.values?.provienceID);
        
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  const handleDeleteWard = (valId) => {
    const deleteUrl = `${process.env.REACT_APP_MAIN_URL}/basic/admin_ward/${valId}/`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .delete(deleteUrl, config)
      .then((response) => {
        if (response.status === 204) {
          toast.success("Ward Deleted successfully");
          getWard(dropDownFormik?.values?.healthZoneID);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  const handleDeleteCommunity = (valId) => {
    const deleteUrl = `${process.env.REACT_APP_MAIN_URL}/basic/admin_community/${valId}/`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .delete(deleteUrl, config)
      .then((response) => {
        if (response.status === 204) {
          toast.success("Community Deleted successfully");
          getWard(dropDownFormik?.values?.healthZoneID);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setName(null);
    formik.resetForm();
    setIsUpdate(null);
  };

  const handleSave = async () => {
    // return console.log("Hit the function")
    const allValue = {
      name: formik.values?.name,
      ...(name === "State" ? { country: 8 } : {}),
      ...(name === "LGA" ? { province: Number(formik.values?.id) } : {}),
      ...(name === "Ward" ? { district: Number(formik.values?.id) } : {}),
      ...(name === "HFN" ? { lga: Number(formik.values?.id) } : {}),
      ...(name === "Provience" ? { country: Number(formik.values?.id) } : {}),
      ...(name === "Division" ? { province: Number(formik.values?.id) } : {}),
      ...(name === "District" ? { division: Number(formik.values?.id) } : {}),
      ...(name === "Community"
      ? { district: Number(formik.values?.id) }
      : {}),
    };
    if (!allValue?.name) {
      return toast.error("Name is Required");
    }
    const endpoints = {
      Country: {
        url: `${process.env.REACT_APP_MAIN_URL}/basic/create_state_for_admin`,
        onSuccess: () => {
          getState();
        },
      },

      State: {
        url: `${process.env.REACT_APP_MAIN_URL}/basic/create_province_for_admin`,
        onSuccess: () => {
          getState();
        },
      },
      LGA: {
        url: `${process.env.REACT_APP_MAIN_URL}/basic/create_district_for_admin`,
        onSuccess: () => {
          getHealthZone(dropDownFormik.values.provienceID);
        },
      },
      Ward: {
        url: `${process.env.REACT_APP_MAIN_URL}/basic/admin_ward/`,
        onSuccess: () => {
          getWard(dropDownFormik?.values?.healthZoneID);
        },
      },
      Community: {
        url: `${process.env.REACT_APP_MAIN_URL}/basic/admin_community/`,
        onSuccess: () => {
          getCommunity(dropDownFormik?.values?.healthZoneID);
        },
      },
    };
    if (name === "Country") {
      const countryNames = countryList?.map((item) => item.name.toLowerCase()); // Convert to lowercase
      const inputValue = formik.values.name.toLowerCase(); // Convert to lowercase

      if (countryNames.includes(inputValue)) {
        return toast.error("Country name already exists");
      }
    }

    if (name === "State") {
      const stateNames = stateList?.map((item) => item.name.toLowerCase()); // Convert to lowercase
      const inputValue = formik.values.name.toLowerCase(); // Convert to lowercase

      if (stateNames.includes(inputValue)) {
        return toast.error("State name already exists");
      }
    }
    if (name === "LGA") {
      const lgaNames = healthZoneList?.map((item) => item.name.toLowerCase()); // Convert to lowercase
      const inputValue = formik.values.name.toLowerCase(); // Convert to lowercase

      if (lgaNames.includes(inputValue)) {
        return toast.error("LGA name already exists");
      }
    }
    if (name === "Ward") {
      const wardNames = wardList?.map((item) => item.name.toLowerCase()); // Convert to lowercase
      const inputValue = formik.values.name.toLowerCase(); // Convert to lowercase

      if (wardNames.includes(inputValue)) {
        return toast.error("Ward name already exists");
      }
    }

    const { url, onSuccess } = endpoints[name];
    if (url) {
      try {
        setLoading(true);
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.post(url, allValue, config);
        if (response.status === 201 || response.status === 200) {
          setLoading(false);
          toast.success("Saved Successfully");
          formik.resetForm();
          onSuccess();
        }
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong");
      }
    }
    setShowModal(false);
  };

  return (
    <div className="mt-12">
      <HeaderTopBar
        icon={<FaFileSignature className="text-gray-700 text-2xl mr-2" />}
        title={"Administration"}
        subtitle={"DRC"}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        {/* State Start */}
        <div className="relative">
          {/* State portion */}
          <div className="bg-white shadow-md p-4 rounded-md h-[510px]">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="mb-4 md:mb-0 md:mr-4">
                <p className="font-semibold text-sm">Provience</p>
              </div>
              <div className="flex flex-col md:flex-row items-center">
                <button
                  className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded mb-2 md:mb-0 md:mr-2"
                  onClick={() => {
                    openModal();
                    setName("State");
                  }}
                >
                  Create
                </button>
                <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded">
                  Search
                </button>
              </div>
            </div>
            <br />
            <hr />
            <div className="mt-2">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-100 rounded-sm">
                    <th className="p-2">Code</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.state?.map((val, idx) => (
                    <tr>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="code"
                          id="code"
                          name="code"
                          onChange={(e) => onStateChange(e, idx, "code")}
                          value={formik.values.state?.[idx]?.code}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="Name"
                          id="name"
                          name="name"
                          onChange={(e) => onStateChange(e, idx, "name")}
                          value={formik.values.state?.[idx]?.name}
                          disabled="true"
                        />
                      </td>

                      <td className="p-2 text-center">
                        <>
                          <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-2 rounded "
                            onClick={() => {
                              setName("State");
                              setIsUpdate(val);
                              openModal();
                            }}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-blue-900 rounded-full"
                                viewBox="0 0 24 24"
                              ></svg>
                            ) : (
                              <BiEdit size={20} />
                            )}
                          </button>
                          <button
                            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => handleDeleteState(val.id)}
                            type="button"
                          >
                            <AiFillDelete size={20} />
                          </button>
                        </>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {paginatedStateData?.length > 0 ? (
              <div className="flex items-center justify-center mt-8 space-x-4">
                {currentStatePage > 1 && (
                  <button
                    type="button"
                    onClick={() => handleStateChange(currentStatePage - 1)}
                    disabled={currentStatePage === 1}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Previous
                  </button>
                )}

                {currentStatePage < 1 ? (
                  <button
                    type="button"
                    onClick={() => handleStateChange(currentStatePage + 1)}
                    disabled
                    className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handleStateChange(currentStatePage + 1)}
                    disabled={currentStatePage === totalStatePages}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Next
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
                No Data Found
              </div>
            )}
          </div>
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75">
              <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid h-12 w-12"></div>
            </div>
          )}
        </div>
        {/* State End */}

        <div className="relative">
          {/* LGA Start */}
          <div className="bg-white shadow-md p-4 rounded-md h-[510px]">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="flex flex-col md:flex-row items-center">
                <div className="mb-4 md:mb-0 md:mr-4">
                  <p className="font-semibold text-sm">Health Zones</p>
                </div>
                <div className="mt-4">
                  <select
                    id="provienceID"
                    name="provienceID"
                    className="w-full border p-2 mb-4"
                    value={dropDownFormik.values.provienceID}
                    onChange={dropDownFormik.handleChange}
                  >
                    <option value="">Select a Provience</option>
                    {stateList.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="flex flex-col md:flex-row items-center">
                <button
                  className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded mb-2 md:mb-0 md:mr-2"
                  onClick={() => {
                    openModal();
                    setName("LGA");
                  }}
                >
                  Create
                </button>
                <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded">
                  Search
                </button>
              </div>
            </div>
            <br />
            <hr />
            <div className="mt-2">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-100 rounded-sm">
                    <th className="p-2">Code</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.lga?.map((val, idx) => (
                    <tr>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="code"
                          id="code"
                          name="code"
                          onChange={(e) => onLgaChange(e, idx, "code")}
                          value={formik.values.lga?.[idx]?.code}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="Name"
                          id="name"
                          name="name"
                          onChange={(e) => onLgaChange(e, idx, "name")}
                          value={formik.values.lga?.[idx]?.name}
                          disabled="true"
                        />
                      </td>

                      <td className="p-2 text-center">
                        <>
                          <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => {
                              setName("LGA");
                              setIsUpdate(val);
                              openModal();
                            }}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-blue-900 rounded-full"
                                viewBox="0 0 24 24"
                              ></svg>
                            ) : (
                              <BiEdit size={20} />
                            )}
                          </button>
                          <button
                            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => handleDeleteLga(val.id)}
                            type="button"
                          >
                            <AiFillDelete size={20} />
                          </button>
                        </>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {paginatedLgaData?.length > 0 ? (
              <div className="flex items-center justify-center mt-8 space-x-4">
                {currentLgaPage > 1 && (
                  <button
                    type="button"
                    onClick={() => handleLgaChange(currentLgaPage - 1)}
                    disabled={currentLgaPage === 1}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Previous
                  </button>
                )}
                {currentLgaPage < 1 ? (
                  <button
                    type="button"
                    onClick={() => handleLgaChange(currentLgaPage + 1)}
                    disabled
                    className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handleLgaChange(currentLgaPage + 1)}
                    disabled={currentLgaPage === totalLgaPages}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Next
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
                No Data Found
              </div>
            )}
          </div>
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75">
              <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid h-12 w-12"></div>
            </div>
          )}
        </div>

        <div className="relative">
          {/* Ward */}
          <div className="bg-white shadow-md p-4 rounded-md h-[510px]">
            <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="flex flex-col md:flex-row items-center">
                <div className="mb-4 md:mb-0 md:mr-4">
                  <p className="font-semibold text-sm">Health Areas</p>
                </div>
                <div className="mt-4">
                  <select
                    id="healthZoneID"
                    name="healthZoneID"
                    className="w-full border p-2 mb-4"
                    value={dropDownFormik.values.healthZoneID}
                    onChange={dropDownFormik.handleChange}
                  >
                    <option value="">Select Health Zone</option>
                    {healthZoneList.map((lga) => (
                      <option key={lga.id} value={lga.id}>
                        {lga.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex flex-col md:flex-row items-center">
                <button
                  className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded mb-2 md:mb-0 md:mr-2"
                  onClick={() => {
                    openModal();
                    setName("Ward");
                  }}
                >
                  Create
                </button>
                <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded">
                  Search
                </button>
              </div>
            </div>
            <br />
            <hr />
            <div className="mt-2">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-100 rounded-sm">
                    <th className="p-2">Code</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.ward?.map((val, idx) => (
                    <tr>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="code"
                          id="code"
                          name="code"
                          onChange={(e) => onWardChange(e, idx, "code")}
                          value={formik.values.ward?.[idx]?.code}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="Name"
                          id="name"
                          name="name"
                          onChange={(e) => onWardChange(e, idx, "name")}
                          value={formik.values.ward?.[idx]?.name}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2 text-center">
                        <>
                          <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => {
                              setName("Ward");
                              setIsUpdate(val);
                              openModal();
                            }}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-blue-900 rounded-full"
                                viewBox="0 0 24 24"
                              ></svg>
                            ) : (
                              <BiEdit size={20} />
                            )}
                          </button>
                          <button
                            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => handleDeleteWard(val?.district)}
                            type="button"
                          >
                            <AiFillDelete size={20} />
                          </button>
                        </>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {paginatedWardData?.length > 0 ? (
              <div className="flex items-center justify-center mt-8 space-x-4">
                {currentWardPage > 1 && (
                  <button
                    type="button"
                    onClick={() => handleWardChange(currentWardPage - 1)}
                    disabled={currentWardPage === 1}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Previous
                  </button>
                )}

                {currentWardPage < 1 ? (
                  <button
                    type="button"
                    onClick={() => handleWardChange(currentWardPage + 1)}
                    disabled
                    className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handleWardChange(currentWardPage + 1)}
                    disabled={currentWardPage === totalWardPages}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Next
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
                No Data Found
              </div>
            )}
          </div>
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75">
              <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid h-12 w-12"></div>
            </div>
          )}
        </div>
        {/* Ward Close */}
        {/* Community Start */}
        <div className="relative">
          <div className="bg-white shadow-md p-4 rounded-md h-[510px]">
            <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="flex flex-col md:flex-row items-center">
                <div className="mb-4 md:mb-0 md:mr-4">
                  <p className="font-semibold text-sm">Community</p>
                </div>
                {/* <div className="mt-4">
                  <select
                    id="wardID"
                    name="wardID"
                    className="w-full border p-2 mb-4"
                    value={dropDownFormik.values.wardID}
                    onChange={dropDownFormik.handleChange}
                  >
                    <option value="">Select Health Area</option>
                    {wardList.map((ward) => (
                      <option key={ward.id} value={ward.id}>
                        {ward.name}
                      </option>
                    ))}
                  </select>
                </div> */}
              </div>
              <div className="flex flex-col md:flex-row items-center">
                <button
                  className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded mb-2 md:mb-0 md:mr-2"
                  onClick={() => {
                    openModal();
                    setName("Community");
                  }}
                >
                  Create
                </button>
                <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded">
                  Search
                </button>
              </div>
            </div>
            <br />
            <hr />
            <div className="mt-2">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-100 rounded-sm">
                    <th className="p-2">Code</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.community?.map((val, idx) => (
                    <tr>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="code"
                          id="code"
                          name="code"
                          onChange={(e) => onCommunityChange(e, idx, "code")}
                          value={formik.values.community?.[idx]?.code}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="Name"
                          id="name"
                          name="name"
                          onChange={(e) => onCommunityChange(e, idx, "name")}
                          value={formik.values.community?.[idx]?.name}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2 text-center">
                        <>
                          <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => {
                              setName("Community");
                              setIsUpdate(val);
                              openModal();
                            }}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-blue-900 rounded-full"
                                viewBox="0 0 24 24"
                              ></svg>
                            ) : (
                              <BiEdit size={20} />
                            )}
                          </button>
                          <button
                            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => handleDeleteCommunity(val?.district)}
                            type="button"
                          >
                            <AiFillDelete size={20} />
                          </button>
                        </>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {paginatedWardData?.length > 0 ? (
              <div className="flex items-center justify-center mt-8 space-x-4">
                {currentWardPage > 1 && (
                  <button
                    type="button"
                    onClick={() => handleCommunityChange(currentCommunityPage - 1)}
                    disabled={currentCommunityPage === 1}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Previous
                  </button>
                )}

                {currentCommunityPage < 1 ? (
                  <button
                    type="button"
                    onClick={() => handleWardChange(currentCommunityPage + 1)}
                    disabled
                    className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handleWardChange(currentCommunityPage + 1)}
                    disabled={currentCommunityPage === totalWardPages}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Next
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
                No Data Found
              </div>
            )}
          </div>
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75">
              <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid h-12 w-12"></div>
            </div>
          )}
        </div>
        {/* Community Close */}
      </div>
      {showModal && (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
          <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
          <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-between items-center pb-3">
                <p className="text-lg font-semibold text-gray-500">
                  {/* {isUpdate ? "Update" : "Save"} */}
                  Country - DRC
                </p>
                <button className="modal-close" onClick={closeModal}>
                  X
                </button>
              </div>
              <hr style={{ borderColor: "#808080", width: "100%" }} />
              <p className="text-lg font-semibold mt-2 text-gray-500">
                New Parent
              </p>
              <input
                id="name"
                name="name"
                labelFor="name"
                type="text"
                className="w-full border p-2 mb-4 mt-5"
                placeholder="Enter Name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <hr
                style={{ borderColor: "#808080", width: "100%" }}
                className="mb-4"
              />
              {name === "Provience" && (
                <select
                  id="id"
                  name="id"
                  className="w-full border p-2 mb-4"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                >
                  <option value="">
                    {isUpdate ? formik.values?.id?.name : "Select a Country"}
                  </option>
                  {countryList.map((provience) => (
                    <option key={provience.id} value={provience.id}>
                      {provience.name}
                    </option>
                  ))}
                </select>
              )}

              {name === "LGA" && (
                <select
                  id="id"
                  name="id"
                  className="w-full border p-2 mb-4"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                >
                  <option value="">
                    {isUpdate ? formik.values?.id?.name : "Select a Provience"}
                  </option>
                  {stateList.map((lga) => (
                    <option key={lga.id} value={lga.id}>
                      {lga.name}
                    </option>
                  ))}
                </select>
              )}

              {name === "Ward" && (
                <select
                  id="id"
                  name="id"
                  className="w-full border p-2 mb-4"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                >
                  <option value="">
                    {isUpdate ? formik.values?.id?.name : "Select Health Zone"}
                  </option>
                  {healthZoneList.map((ward) => (
                    <option key={ward.id} value={ward.id}>
                      {ward.name}
                    </option>
                  ))}
                </select>
              )}
              {name === "Community" && (
                <select
                  id="id"
                  name="id"
                  className="w-full border p-2 mb-4"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                >
                  <option value="">
                    {isUpdate ? formik.values?.id?.name : "Select Health Zone"}
                  </option>
                  {healthZoneList.map((ward) => (
                    <option key={ward.id} value={ward.id}>
                      {ward.name}
                    </option>
                  ))}
                </select>
              )}

              {name === "HFN" && (
                <select
                  id="id"
                  name="id"
                  className="w-full border p-2 mb-4"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                >
                  <option value="">
                    {isUpdate ? formik.values?.id?.name : "Select a LGA"}
                  </option>
                  {healthZoneList.map((lga) => (
                    <option key={lga.id} value={lga.id}>
                      {lga.name}
                    </option>
                  ))}
                </select>
              )}

              <button
                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
                onClick={isUpdate ? handleEditClick : handleSave}
                type="button"
                disabled={loading}
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V2C5.373 2 2 5.373 2 10h2zm2 0a6 6 0 016-6V4a4 4 0 00-4 4H6zm6 0a6 6 0 016 6h2a8 8 0 00-8-8V2zm6 0a10 10 0 00-10 10h2a8 8 0 018-8z"
                    ></path>
                  </svg>
                ) : (
                  `${isUpdate ? "Update" : "Save"} `
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
