/* eslint-disable jsx-a11y/img-redundant-alt */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { AiOutlineUser } from "react-icons/ai";
import { CiTimer } from "react-icons/ci";
import { FaEdit, FaSave } from "react-icons/fa";
import Loader from "../../Loader/Loader";


export default function EnrollmentChildDetail() {
  const location = useLocation();
  const [childList, setChildList] = useState();
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const token = localStorage.getItem("token");

  const updateInitialValue = {
    ...childList,
    // childId:childList?.childId,
    // detail: childList?.map((val) => {
    //   return {
    //     ...val,
    //     childId: val?.childId,
    //     dateOfBirth: val?.dateOfBirth,
    //     firstname: val?.firstname,
    //     lastnames: val?.lastnames,
    //     otherNames: val?.otherNames,
    //   };
    // }) || [
    //   {
    //     childId: 1,
    //     firstname: "Baby",
    //     lastnames: "Pro 2",
    //     otherNames: "",
    //     dateOfBirth: "2023-09-13",
    //   },
    // ],
  };
  //  ---- Start Formik Object -----
  const formik = useFormik({
    initialValues: updateInitialValue,
    onSubmit: (values) => {},
    enableReinitialize: true,
    validateOnChange: false,
  });
  const handleSubmit = async (values) => {
    // upload image off
    // if (values.caregiver_child_photo.includes('/media/')){
    //   delete values["caregiver_child_photo"];
    // }
    // if (values.vaccination_card_photo.includes('/media/')){
    //   delete values["vaccination_card_photo"];
    // }
    // if (values.childProfile.includes('/media/')){
    //   delete values["childProfile"]
    // }
    try{
      delete values["caregiver_child_photo"];
      delete values["vaccination_card_photo"];
      delete values["childProfile"];
    }catch(err){
      console.log(err);
    }
    const allValue = {
      ...values
      // childId: values?.childId,
      // dateOfBirth: values?.dateOfBirth,
      // firstname: values?.firstname,
      // lastnames: values?.lastnames,
      // otherNames: values?.otherNames,
    };
    setBtnLoading(true);
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/child/child/${values?.qrcodeid}`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put(apiUrl, allValue, config);
      if (response.status === 200) {
        setBtnLoading(false);
        toast.success("Update Sucessfully");
        if (id) {
          fetchData(id);
        }
      }
    } catch (error) {
      setBtnLoading(false);
      toast.error("Something went wrong");
    }
  };
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log("Child List", childList);
  const fetchData = (id) => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/child/child/${id}`;
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setChildList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const onChange = (e, fieldName) => {
    formik.setFieldValue(`${fieldName}`, e.target.value);
  };

  const inputConfigs = [
    {
      label: "Child ID",
      id: "qrcodeid",
      name: "qrcodeid",
      type: "text",
      placeholder: "Enter ID",
    },
    {
      label: "Address",
      id: "address",
      name: "address",
      type: "text",
      placeholder: "Address",
    },
    {
      label: "Date Enrolled",
      id: "date_enrolled",
      name: "date_enrolled",
      type: "date",
      placeholder: "Date Enrolled",
    },
    {
      label: "Enrollment Officer ID",
      id: "enrollment_officer",
      name: "enrollment_officer",
      type: "text",
      placeholder: "Enter ID",
    },
    // {
    //   label: "Picture of Caregiver and Child",
    //   id: "childId",
    //   name: "childId",
    //   type: "text",
    //   placeholder: "Enter ID",
    // },
    {
      label: "Child First Name",
      id: "firstname",
      name: "firstname",
      type: "text",
      placeholder: "First Name",
    },
    {
      label: "Child Last Name",
      id: "lastnames",
      name: "lastnames",
      type: "text",
      placeholder: "Last Name",
    },
    {
      label: "Country",
      id: "country",
      name: "country",
      type: "text",
      placeholder: "Country",
    },
    // {
    //   label: "Picture of Child",
    //   id: "childId",
    //   name: "childId",
    //   type: "text",
    //   placeholder: "Enter ID",
    // },
    {
      label: "Other Names",
      id: "other_caregiver",
      name: "other_caregiver",
      type: "text",
      placeholder: "Other Name",
    },
    {
      label: "State",
      id: "state",
      name: "state",
      type: "text",
      placeholder: "State",
    },
    {
      label: "Date of Birth",
      id: "dateOfBirth",
      name: "dateOfBirth",
      type: "date",
      placeholder: "Select DOB",
    },
    {
      label: "LGA",
      id: "lga",
      name: "lga",
      type: "text",
      placeholder: "LGA",
    },
    {
      label: "Gender",
      id: "gender",
      name: "gender",
      type: "text",
      placeholder: "Gender",
    },
    {
      label: "Ward",
      id: "ward",
      name: "ward",
      type: "text",
      placeholder: "Ward",
    },
    {
      label: "Father's Name",
      id: "father_name",
      name: "father_name",
      type: "text",
      placeholder: "Father name",
    },
    {
      label: "Community",
      id: "community",
      name: "community",
      type: "text",
      placeholder: "Community",
    },
    {
      label: "Mother's Name",
      id: "mother_name",
      name: "mother_name",
      type: "text",
      placeholder: "Mother's Name",
    },
    {
      label: "Geolocation",
      id: "geolocation",
      name: "geolocation",
      type: "text",
      placeholder: "GeoLocation",
    },
    {
      label: "Caregiver Name",
      id: "caregiver",
      name: "caregiver",
      type: "text",
      placeholder: "Caregiver",
    },
    {
      label: "Caregiver Phone Number",
      id: "caregiver_phone",
      name: "caregiver_phone",
      type: "text",
      placeholder: "Caregiver Phone Number",
    },
    {
      label: "Caregiver Presenting the Child for Enrollment",
      id: "caregiver_presenting_child_enrollment",
      name: "caregiver_presenting_child_enrollment",
      type: "text",
      placeholder: "",
    },
  ];

  const navigate = useNavigate();
  const handleHistory = (item) => {
    const historyLink = `/child/history?id=${item?.qrcodeid}`;
    navigate(historyLink);
  };

  const handleEditClick = (index) => {
    // setIsEditing((prev) => {
    //   const newEditingState = [...prev];
    //   // newEditingState[index] = !newEditingState[index];
    //   return newEditingState;
    // });
    setIsEditing(true);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        // Update the formik values with the image URL
        formik.setFieldValue('caregiver_child_photo', reader.result);
      };
  
      reader.readAsDataURL(file);
    }
  };
  

  return (
    <div className="text-center mt-16">
      {loading && <Loader />}
      <div className="p-4 bg-white rounded-lg shadow-md flex flex-col md:flex-row mt-6">
        <div className="lg:w-9/12 md:w-8/12 sm:w-full text-gray-700 text-l font-bold flex items-start sm:items-center">
          CHILD'S PROFILE
        </div>
      </div>
      {/* {formik.values?.detail?.map((item, idx) => ( */}
      <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden mt-4">
        {/* {console.log("item", item)} */}
        <form onSubmit={formik.handleSubmit}>
          <div className="p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 items-center">
              <div className="col-span-1">
                <div className="w-32 h-32 bg-black flex items-center justify-center rounded-full">
                  {formik?.values?.childProfile ? (
                    <img
                      src={process.env.REACT_APP_MAIN_URL+formik.values?.childProfile}
                      alt="User"
                      className="w-32 h-32 rounded-full"
                    />
                  ) : (
                    <AiOutlineUser size={64} color="white" />
                  )}
                </div>
              </div>
              <div className="col-span-3">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 text-sm">
                  {inputConfigs.map((config, index) => (
                    <div
                      key={index}
                      className="col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1 flex flex-col items-center justify-center min-w-[150px] border border-gray-300 p-2 rounded-md bg-white shadow-md"
                    >
                      <label className="text-left mb-2 text-gray-600">
                        {config.label}:
                      </label>
                      <input
                        className="w-full h-8 px-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        type={config.type}
                        id={config.id}
                        name={config.name}
                        placeholder={config.placeholder}
                        value={formik.values?.[config.name]}
                        onChange={(e) => onChange(e, config.name)}
                        disabled={!isEditing}
                      />
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 mt-4 gap-2">
                  {/* First Image */}
                  <div className="col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1 flex flex-col items-center justify-center min-w-[150px] border border-gray-300 p-4 rounded-md bg-white shadow-md">
                    <label className="text-left mb-2 text-gray-600">
                      Picture of Caregiver And Child
                    </label>
                    <div className="relative w-32 h-32 rounded-full overflow-hidden">
                      <img
                        src={process.env.REACT_APP_MAIN_URL+formik.values?.caregiver_child_photo} // Replace with the actual image source
                        alt="Image Preview"
                        className="w-full h-full object-cover"
                      />
                      {/* Input Field for Image */}
                      <input
                        type="file"
                        id="caregiver_child_photo"
                        name="caregiver_child_photo"
                        accept="image/*"
                        className="absolute inset-0 opacity-0"
                        onChange={(e) => handleImageChange(e)}
                        disabled={!isEditing}
                      />
                    </div>
                    
                  </div>
                  {/* Second Image */}
                  <div className="col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1 flex flex-col items-center justify-center min-w-[150px] border border-gray-300 p-4 rounded-md bg-white shadow-md">
                    <label className="text-left mb-2 text-gray-600">
                      Picture of Child Vaccination
                    </label>
                    <div className="relative w-32 h-32 rounded-full overflow-hidden">
                      <img
                        src={process.env.REACT_APP_MAIN_URL+formik.values?.vaccination_card_photo} // Replace with the actual image source
                        alt="Image Preview"
                        className="w-full h-full object-cover"
                      />
                      {/* Input Field for Image */}
                      <input
                        type="file"
                        id="imageInput"
                        name="imageInput"
                        accept="image/*"
                        className="absolute inset-0 opacity-0"
                        onChange={(e) => handleImageChange(e)}
                        disabled={!isEditing}
                      />
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            className="bg-white hover:bg-gray-200 text-black py-2 px-4 rounded-lg flex items-center"
            type="button"
            onClick={() => {
              handleHistory(formik.values);
            }}
          >
            <CiTimer className="mr-2" /> History
          </button>

          <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 p-3">
            <div className="flex items-center space-y-0 space-x-4">
              <button
                className="bg-blue-600 hover:bg-blue-900 text-white py-2 px-4 rounded-lg flex items-center"
                type="button"
                onClick={() => handleEditClick()}
              >
                <FaEdit className="mr-2" /> Edit
              </button>
              <button
                className={`bg-green-600 hover:bg-green-900 text-white py-2 px-4 rounded-lg flex items-center ${
                  btnLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                type="button"
                onClick={() => handleSubmit(formik.values)}
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <div className="flex items-center">
                    <div className="animate-spin mr-2 h-5 w-5 border-t-2 border-b-2 border-green-600 rounded-full"></div>
                    <span>Loading...</span>
                  </div>
                ) : (
                  <>
                    <FaSave className="mr-2" />
                    Save
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* ))} */}
    </div>
  );
}
