import React from "react";
import { HiOutlineCog, HiOutlineCube, HiOutlineViewGrid } from "react-icons/hi";
import { GiCash } from "react-icons/gi";
import { AiFillBell } from "react-icons/ai";
export const Dashboard_Sidebar_Links = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/",
    icon: <HiOutlineViewGrid />,
  },
  {
    key: "enrollment",
    label: "Enrollment Child",
    path: "/emrollment/child",
    icon: <HiOutlineCube />,
  },
  {
    key: "enrollmentOfficer",
    label: "Enrollment Officer",
    path: "/emrollment/officer",
    icon: <HiOutlineCube />,
  },
  {
    key: "healthFacility",
    label: "Enrollment Health Facility",
    path: "/emrollment/health-facility",
    icon: <HiOutlineCube />,
  },
  {
    key: "incentive Child",
    label: "Incentive Child",
    path: "/incentive/child",
    icon: <HiOutlineCube />,
  },
  {
    key: "incentiveHealthFacility",
    label: "Incentive Health Facility",
    path: "/emrollment/incentive-health-facility",
    icon: <HiOutlineCube />,
  },
  // {
  //     key:'transfer',
  //     label:'Cash Transfer',
  //     path:'/transfer',
  //     icon:<GiCash/>
  // },
  {
    key: "reminder",
    label: "Reminder",
    path: "/reminder",
    icon: <AiFillBell />,
  },
  {
    key: "administration",
    label: "Administration",
    icon: <AiFillBell />,
    countrys: [
      {
        id: 1,
        name: "Nigeria",
        path: "/administration/Nigeria",
      },
      {
        id: 2,
        name: "Cameron",
        path: "/administration/Cameron",
      },
      {
        id: 3,
        name: "DRC",
        path: "/administration/DRC",
      },
      {
        id: 4,
        name: "Lesotho",
        path: "/administration/Lesotho",
      },
    ],
  },
  {
    key: "defaulterTracking",
    label: "Defaulter Tracking",
    path: "/defaulter/tracking",
    icon: <AiFillBell />,
  },
];

export const Dashboard_Sidebar_Bottom_Links = [
  {
    key: "settings",
    label: "Settings",
    // path:'/settings',
    icon: <HiOutlineCog />,
  },
];
