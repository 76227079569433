import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";

export default function CheckingDetails() {
    const [loading, setLoading] = useState(false);
    const [caregiverDetail, setCaregiverDetail] = useState([]);
    const [caregiverDetailSingle, SinglesetCaregiverDetailSingle] = useState(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const caregiverId = searchParams.get("caregiverId");
    const childID = searchParams.get("childID");
    const token = localStorage.getItem("token");
    const [isFilter, setIsFilter] = useState(false);
  
    const navigate = useNavigate();
    const handleAddNewClick = () => {
      navigate(
        `/add-transaction?caregiverId=${caregiverId}&childID=${childID}&id=${id}`
      );
    };
  
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    const fetchData = (fid) => {
      const apiUrl = `${process.env.REACT_APP_MAIN_URL}/dashboard/child_with_disbursement_history/${fid}`;
      setLoading(true);
      axios
        .get(apiUrl, axiosConfig)
        .then((response) => {
          setCaregiverDetail(response.data.history);
          SinglesetCaregiverDetailSingle(response.data);
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Something went wrong");
          setLoading(false);
        });
    };
    useEffect(() => {
      if (id) {
        fetchData(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

     //Pagination functionality
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const tableData = caregiverDetail;
  const paginatedData = tableData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="mt-16">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6">
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 items-center">
            <div className="sm:col-span-1">
              <div className="w-32 h-32 bg-black flex items-center justify-center rounded-full">
                {/* <AiOutlineUser size={64} color="white" /> */}
                <img src={`${process.env.REACT_APP_MAIN_URL}/${caregiverDetailSingle?.caregiver_child_photo}`} />
              </div>
            </div>
            <div className="sm:col-span-3">
              <div className="text-lg text-gray-600 dark:text-gray-400">
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4">child ID:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetailSingle?.qrcodeid}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4">Child First Name:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetailSingle?.firstname}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4">Child Last Name:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetailSingle?.lastnames}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4">Gender:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetailSingle?.gender}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4">Date Of Birth:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetailSingle?.dateOfBirth}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm w-1/4">Address:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetailSingle?.address}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm w-1/4">Fathers Name:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetailSingle?.father_name}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm w-1/4">Mothers Name:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetailSingle?.mother_name}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm w-1/4">Caregiver Phone Number:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetailSingle?.caregiver_phone}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between ml-3">
          <span className="text-xl font-semibold">Disbursement History</span>
          {/* <div className="flex items-center mr-2">
            <button
              className="ml-4 bg-blue-500 text-white px-4 py-2 rounded text-sm"
              onClick={handleAddNewClick}
            >
              Add New
            </button>
          </div> */}
        </div>
        {/* <div className="ml-3">
          <select className="w-32 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring focus:border-blue-500">
            <option value="all">All</option>
            <option value="completed">Completed</option>
            <option value="pending">Pending</option>
          </select>
        </div> */}

        <div className="p-3">
          <div className="max-h-64 sm:max-h-full overflow-y-auto">
            <table className="min-w-full bg-white border border-gray-300 shadow-sm rounded-lg text-sm">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-2 px-4 text-left">Vaccination Visit</th>
                  <th className="py-2 px-4 text-left">Date Disbursed</th>
                  <th className="py-2 px-4 text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td className="py-2 px-4">{item.vaccination_visit}</td>
                      <td className="py-2 px-4">{item.date_received}</td>
                      <td className="py-2 px-4">{item.disbursement_type ? "Disbursed" : ""}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>

            {paginatedData?.length > 0 ? (
              <div className="flex items-center justify-center mt-8 space-x-4">
                {currentPage > 1 && (
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Previous
                  </button>
                )}

                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={`${
                      currentPage === index + 1
                        ? "bg-pink-600 text-white font-bold py-2 px-4 rounded"
                        : "bg-pink-200 hover:bg-pink-300 text-pink-800 font-bold py-2 px-4 rounded"
                    } focus:outline-none focus:ring focus:border-pink-300`}
                  >
                    {index + 1}
                  </button>
                ))}

                {(currentPage < 1 && paginatedData?.length > 1)  ? (
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled
                    className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Next
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
                No Data Found
              </div>
            )} 
          </div>
        </div>
      </div>
    </div>
  );
}
