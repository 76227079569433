import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FaEdit, FaSave } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";

export default function OfficerDetail() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [officerDetail, setOfficerDetail] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const token = localStorage.getItem("token");
  const [regionList, setRegionList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [lgaList, setLgaList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const updateInitialValue = {
    ...officerDetail,
    latitude:officerDetail?.geolocation?.latitude || 0,
  };
  //  ---- Start Formik Object -----
  const formik = useFormik({
    initialValues: updateInitialValue,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
    validateOnChange: false,
  });
  const handleSubmit = async (values) => {
    const allValue = {
      ...values,
      Geolocation:{
        latitude: values?.latitude,
      }
    };
    setBtnLoading(true);
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/dashboard/edit_get_officer/${id}`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put(apiUrl, allValue, config);
      if (response.status === 201) {
        setBtnLoading(false);
        toast.success("Saved Sucessfully");
        if (id) {
          fetchData(id);
        }
      }
    } catch (error) {
      setBtnLoading(false);
      toast.error("Something went wrong");
    }
  };
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchData = (id) => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/dashboard/edit_get_officer/${id}`;
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setOfficerDetail(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const regionUrl = `${process.env.REACT_APP_MAIN_URL}/basic/create_region_for_admin`;
  const stateUrl = `${process.env.REACT_APP_MAIN_URL}/basic/create_state_for_admin`;
  const lgaUrl = `${process.env.REACT_APP_MAIN_URL}/basic/get_admin_lga`;
  const wardUrl = `${process.env.REACT_APP_MAIN_URL}/basic/admin_ward/`;
  const getRegion = () => {
    setLoading(true);
    axios
      .get(regionUrl, axiosConfig)
      .then((response) => {
        console.log("Data:", response.data);
        setRegionList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Something went wrong in Region");
        setLoading(false);
      });
  };
  //Get State data
  const getState = () => {
    setLoading(true);
    axios
      .get(stateUrl, axiosConfig)
      .then((response) => {
        console.log("Data:", response.data);
        setStateList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Something went wrong in State");
        setLoading(false);
      });
  };
  //Get LGA data
  const getLga = () => {
    setLoading(true);
    axios
      .get(lgaUrl, axiosConfig)
      .then((response) => {
        console.log("Data:", response.data);
        setLgaList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };

  //Get Ward
  const getWard = () => {
    setLoading(true);
    axios
      .get(wardUrl, axiosConfig)
      .then((response) => {
        console.log("Data:", response.data);
        setWardList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Something went wrong in ward");
        setLoading(false);
      });
  };

  useEffect(() => {
    getRegion();
    getState();
    getLga();
    getWard();
  }, []);
  const inputConfigs = [
    {
      label: "Enrollment ID",
      id: "enrollmentId",
      name: "enrollmentId",
      type: "text",
      placeholder: "Enter Enrollment ID",
    },
    {
      label: "Email",
      id: "email",
      name: "email",
      type: "text",
      placeholder: "Email",
    },
    {
      // label: "Geolocation",
      // id: "latitude",
      // name: "latitude",
      // type: "text",
      // placeholder: "GeoLocation",

      label: "Geolocation",
      id: "latitude",
      name: "latitude",
      type: "number",
      placeholder: "GeoLocation",
      pattern: "[0-9]*",
      required: true,
    },
    {
      label: "First Name",
      id: "firstname",
      name: "firstname",
      type: "text",
      placeholder: "First Name",
    },
    {
      label: "Address",
      id: "address",
      name: "address",
      type: "text",
      placeholder: "Address",
    },
    {
      label: "Date Enrolled",
      id: "dateEnrolled",
      name: "dateEnrolled",
      type: "date",
      placeholder: "Enrolled Date",
    },
    {
      label: "Last Name",
      id: "lastname",
      name: "lastname",
      type: "text",
      placeholder: "Last Name",
    },
    {
      label: "Others Name",
      id: "otherNames",
      name: "otherNames",
      type: "text",
      placeholder: "Other Names",
    },
    {
      label: "Date of Birth",
      id: "dateOfBirth",
      name: "dateOfBirth",
      type: "date",
      placeholder: "Date of Birth",
    },
    {
      label: "Gender",
      id: "gender",
      name: "gender",
      type: "text",
      placeholder: "Gender",
    },
    {
      label: "Marital Status",
      id: "maritalStatus",
      name: "maritalStatus",
      type: "text",
      placeholder: "Marital Status",
    },
    {
      label: "Occupation",
      id: "occupation",
      name: "occupation",
      type: "text",
      placeholder: "Occupation",
    },
  ];
  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  console.log("Formik values", formik.values)
  return (
    <div className="text-center  mt-14">
      {loading && <Loader />}
      <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden mt-4">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-6">
            <div className="grid lg:grid-cols-3 md:grid-cols-4 sm:grid-cols-12 gap-4 items-center">
              <div className="col-span-3">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 p-4 bg-gray-100 rounded-md shadow-md">
                  {/* <div className="col-span-3 space-y-4"> */}
                  {inputConfigs.map((config, index) => (
                    <div key={index} className="col-span-1">
                      
                      <label className="text-sm font-semibold text-gray-700 mb-1">
                      {config.label}:
                    </label>
                      {/* <input
                        type={config.type}
                        id={config.id}
                        name={config.name}
                        placeholder={config.placeholder}
                        value={formik.values[config.name]}
                        onChange={formik.handleChange}
                        disabled={!isEditing}
                        className="w-full h-10 border rounded-md px-3 focus:outline-none focus:border-blue-500"
                      /> */}
                      <input
                        className={`w-full h-10 rounded border ${
                          "border-gray-300"
                        } focus:outline-none focus:border-blue-500 px-3`}
                        type={config.type}
                        id={config.id}
                        name={config.name}
                        placeholder={config.placeholder}
                        value={formik.values[config.name]}
                        onChange={formik.handleChange}
                        pattern={config.pattern}
                        disabled={!isEditing}
                      />
                    </div>
                  ))}
                  {/* </div> */}
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 p-4 bg-gray-100 rounded-md shadow-md">
                  <div className="col-span-1 mb-1">
                    <label className="text-sm font-semibold text-gray-700 mb-1">
                      Region <span className="text-red-600">*</span>:
                    </label>
                    <select
                      id="region"
                      name="region"
                      value={formik.values.region}
                      onChange={formik.handleChange}
                      disabled={!isEditing}
                      className="w-full h-10 border rounded-md px-3 focus:outline-none focus:border-blue-500"
                    >
                      <option value="">Select a Region</option>
                      {regionList.map((region) => (
                        <option key={region.id} value={region.name}>
                          {region.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-span-1 mb-1">
                    <label className="text-sm font-semibold text-gray-700 mb-1">
                      State:
                    </label>
                    <select
                      id="state"
                      name="state"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      disabled={!isEditing}
                      className="w-full h-10 border rounded-md px-3 focus:outline-none focus:border-blue-500"
                    >
                      <option value="">Select a State</option>
                      {stateList.map((state) => (
                        <option key={state.id} value={state.name}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-span-1 mb-1">
                    <label className="text-sm font-semibold text-gray-700 mb-1">
                      LGA:
                    </label>
                    <select
                      id="lga"
                      name="lga"
                      value={formik.values.lga}
                      onChange={formik.handleChange}
                      disabled={!isEditing}
                      className="w-full h-10 border rounded-md px-3 focus:outline-none focus:border-blue-500"
                    >
                      <option value="">Select an LGA</option>
                      {lgaList.map((lga) => (
                        <option key={lga.id} value={lga.name}>
                          {lga.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-span-1 mb-1">
                    <label className="text-sm font-semibold text-gray-700 mb-1">
                      Ward:
                    </label>
                    <select
                      id="ward"
                      name="ward"
                      value={formik.values.ward}
                      onChange={formik.handleChange}
                      disabled={!isEditing}
                      className="w-full h-10 border rounded-md px-3 focus:outline-none focus:border-blue-500"
                    >
                      <option value="">Select a Ward</option>
                      {wardList.map((ward) => (
                        <option key={ward.id} value={ward.name}>
                          {ward.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4 p-3">
            <button
              className="bg-blue-600 hover:bg-blue-900 text-white py-2 px-4 rounded-lg flex items-center"
              type="button"
              onClick={handleEditClick}
            >
              <FaEdit className="mr-2" /> Edit
            </button>
            <button
              className={`bg-green-600 hover:bg-green-900 text-white py-2 px-4 rounded-lg flex items-center ${
                btnLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              type="submit"
              disabled={btnLoading}
            >
              {btnLoading ? (
                <div className="flex items-center">
                  <div className="animate-spin mr-2 h-5 w-5 border-t-2 border-b-2 border-green-600 rounded-full"></div>
                  <span>Loading...</span>
                </div>
              ) : (
                <>
                  <FaSave className="mr-2" />
                  Save
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
