import React, { useEffect, useState } from "react";
import Loader from "../../Loader/Loader";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { AiOutlineUser } from "react-icons/ai";
import { FaEdit, FaSave } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const IncentiveHealthFacilityDetail = () => {
    const [careGiverList, setCaregiverList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const token = localStorage.getItem("token");


    console.log("careGiverList",careGiverList)
  
    //Form handler start
    const updateInitialValue = {
      id: careGiverList?.hf?.id || 0,
      enrollment_officer: careGiverList?.hf?.enrollment_officer || "",
      date_enrolled: careGiverList?.hf?.date_enrolled || "",
      name: careGiverList?.hf?.name || "",
      type_of_hf: careGiverList?.hf?.type_of_hf || "",
      ward: careGiverList?.hf?.ward || "",
      community: careGiverList?.hf?.community || "",
      geolocation:careGiverList?.hf?.geolocation || "",
      address: careGiverList?.hf?.address || "",
    };
    //  ---- Start Formik Object -----
    const formik = useFormik({
      initialValues: updateInitialValue,
      onSubmit: (values) => {
        handleSubmit(values);
      },
      enableReinitialize: true,
      validateOnChange: false,
    });
  
    const handleSubmit = async (values) => {
      const allValue = {
        ...values,
      };
      setBtnLoading(true);
      const apiUrl = `${process.env.REACT_APP_MAIN_URL}/dashboard/edit_get_hf/${id}`;
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.put(apiUrl, allValue, config);
        if (response.status === 200) {
          setBtnLoading(false);
          toast.success("Saved Sucessfully");
          console.log("Response:", response.data);
          if (id) {
            fetchData(id);
          }
        }
      } catch (error) {
        setBtnLoading(false);
        console.error("Error:", error);
      }
    };
  
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    const fetchData = (id) => {
      const apiUrl = `${process.env.REACT_APP_MAIN_URL}/dashboard/get_hf_disbursement_history/${id}`;
      setLoading(true);
      axios
        .get(apiUrl, axiosConfig)
        .then((response) => {
          setCaregiverList(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong");
        });
    };
    useEffect(() => {
      if (id) {
        fetchData(id);
      }
    }, [id]);
  
    const inputConfigs = [
      {
        label: "Health facility ID",
        id: "id",
        name: "id",
        type: "text",
        placeholder: "Enter ID",
      },
      {
        label: "Enrollment Officer ID",
        id: "enrollment_officer",
        name: "enrollment_officer",
        type: "text",
        placeholder: "Enter ID",
      },
      {
        label: "Date Enrolled",
        id: "date_enrolled",
        name: "date_enrolled",
        type: "date",
        placeholder: "Enter Date",
      },
      {
        label: "Name of Officer in-charge",
        id: "name",
        name: "name",
        type: "text",
        placeholder: "Enter Name",
      },
      {
        label: "Type of Health Facility",
        id: "type_of_hf",
        name: "type_of_hf",
        type: "text",
        placeholder: "HF Type",
      },
      {
        label: "Ward",
        id: "ward",
        name: "ward",
        type: "text",
        placeholder: "ward",
      },
      {
        label: "Community",
        id: "community",
        name: "community",
        type: "text",
        placeholder: "Community",
      },
      {
        label: "Address",
        id: "address",
        name: "address",
        type: "text",
        placeholder: "Address",
      },
      {
        label: "Geolocation",
        id: "geolocation",
        name: "geolocation",
        type: "text",
        placeholder: "Geolocation",
      },
    ];
  
    // const handleEditClick = () => {
    //   setIsEditing(!isEditing);
    // };
    const navigate = useNavigate();
    const handleEditClick = () => {
      const detailLink = `/edit-health-facility?id=${id}`;
      navigate(detailLink);
    };
    return (
        <div>
        <div className="text-center mt-16">
          {loading && <Loader />}
          <div className="p-4 bg-white rounded-lg shadow-md flex flex-col md:flex-row mt-6">
            <div className="lg:w-9/12 md:w-8/12 sm:w-full text-gray-700 text-l font-bold flex items-start sm:items-center">
              HEALTH FACILITY PROFILE
            </div>
          </div>
  
          <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden mt-4">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 items-center">
                  <div className="col-span-1">
                    <div className="w-32 h-32 bg-black flex items-center justify-center rounded-full">
                      {careGiverList?.photoUrl ? (
                        <img
                          src={careGiverList?.photoUrl}
                          alt="User"
                          className="w-32 h-32 rounded-full"
                        />
                      ) : (
                        <AiOutlineUser size={64} color="white" />
                      )}
                    </div>
                  </div>
                  <div className="col-span-1 md:col-span-1 lg:col-span-2 xl:col-span-2">
                    <div className="grid grid-cols-1 gap-4 text-xs">
                      {inputConfigs.map((config, index) => (
                        <div
                          key={index}
                          className="col-span-1 flex items-center min-w-[50px]"
                        >
                          <label>{config.label}:</label>
                          <input
                            className="ml-2 h-8"
                            type={config.type}
                            id={config.id}
                            name={config.name}
                            placeholder={config.placeholder}
                            value={formik.values[config.name]}
                            onChange={formik.handleChange}
                            disabled={!isEditing}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 space-x-0 md:space-x-4 p-3">
                <button
                  className="w-full md:w-auto bg-blue-600 hover:bg-blue-900 text-white py-2 px-4 rounded-lg flex items-center"
                  type="button"
                  onClick={handleEditClick}
                >
                  <FaEdit className="mr-2" /> Edit
                </button>
                <button
                  className={`w-full md:w-auto bg-green-600 hover:bg-green-900 text-white py-2 px-4 rounded-lg flex items-center ${
                    btnLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  type="submit"
                  onClick={handleSubmit}
                  disabled={btnLoading}
                >
                  {btnLoading ? (
                    <div className="flex items-center">
                      <div className="animate-spin mr-2 h-5 w-5 border-t-2 border-b-2 border-green-600 rounded-full"></div>
                      <span>Loading...</span>
                    </div>
                  ) : (
                    <>
                      <FaSave className="mr-2" />
                      Save
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
};

export default IncentiveHealthFacilityDetail;