import React, { useEffect, useState } from "react";
import HeaderTopBar from "../../HeaderTopBar";
import { FaFileSignature, FaSave } from "react-icons/fa";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

export default function EditHealthFacility() {
  const [loading, setLoading] = useState();
  const [wardList, setWardList] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const location = useLocation();
  const token = localStorage.getItem("token");
  const searchParams = new URLSearchParams(location.search);
  const [btnLoading, setBtnLoading] = useState(false);
  const id = searchParams.get("id");
  const [hfList, setHFList] = useState(null);
  const formik = useFormik({
    initialValues: {
      ...hfList,
     
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log("Hit the Function");
      handleSubmit(values);
    },
  });
  const handleSubmit = async (values) => {
    const allValue = {
      ...values,
    };
    setBtnLoading(true);
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/dashboard/edit_get_hf/${formik.values.qrcodeid}`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put(apiUrl, allValue, config);
      if (response.status === 200) {
        setBtnLoading(false);
        toast.success("Saved Sucessfully");
        console.log("Response:", response.data);
        if (id) {
          fetchData(id);
        }
      }
    } catch (error) {
      setBtnLoading(false);
      console.error("Error:", error);
    }
  };

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchData = (id) => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/dashboard/get_hf_disbursement_history/${id}`;
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setHFList(response.data.hf);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  //Input Fields
  const inputConfigs = [
    {
      label: "Health Facility ID",
      id: "id",
      name: "id",
      type: "number",
      placeholder: "Enter ID",
      pattern: "[0-9]*",
      required: true,
    },
    {
      label: "Enrolment Officer ID",
      id: "enrollment_officer",
      name: "enrollment_officer",
      type: "number",
      placeholder: "Enter ID",
      pattern: "[0-9]*",
      required: true,
    },
    {
      label: "Date Enrolled",
      id: "date_enrolled",
      name: "date_enrolled",
      type: "date",
      placeholder: "Enrolled Date",
    },
    {
      label: "Name of Officer in-charge of Health Facility",
      id: "name",
      name: "name",
      type: "text",
      placeholder: "",
      required: true,
    },
    {
      label: "Geolocation",
      id: "geolocation",
      name: "geolocation",
      type: "text",
      placeholder: "GeoLocation",
      required: true,
    },

    {
      label: "address",
      id: "address",
      name: "address",
      type: "text",
      placeholder: "Address",
      required: true,
    },
    {
      label: "Type of health facility",
      id: "type_of_hf",
      name: "type_of_hf",
      type: "text",
      placeholder: "type_of_hf",
      required: true,
    },
  ];
  // const axiosConfig = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };
  const wardUrl = `${process.env.REACT_APP_MAIN_URL}/basic/admin_ward/`;
   //Get Ward
   const getWard = () => {
    setLoading(true);
    axios
      .get(wardUrl, axiosConfig)
      .then((response) => {
        console.log("Data:", response.data);
        setWardList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Something went wrong in ward");
        setLoading(false);
      });
  };

  const communityUrl = `${process.env.REACT_APP_MAIN_URL}/basic/admin_community/`;
   //Get Ward
   const getCommunity = () => {
    setLoading(true);
    axios
      .get(communityUrl, axiosConfig)
      .then((response) => {
        console.log("Data:", response.data);
        setCommunityList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Something went wrong in ward");
        setLoading(false);
      });
  };

  useEffect(() => {
    getWard();
    getCommunity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-16">
      {" "}
      <HeaderTopBar
        icon={<FaFileSignature className="text-gray-700 text-2xl mr-2" />}
        title={"Enrollment"}
        subtitle={"Health Facility"}
      />
      <form onSubmit={formik.handleSubmit}>
        <div className="p-4 bg-white rounded-lg shadow-md flex flex-col md:flex-row mt-4">
          <div className="lg:w-9/12 md:w-8/12 sm:w-full text-gray-700 text-l font-bold flex items-start sm:items-center">
            EDIT HEALTH FACILITY
          </div>
          <div className="lg:w-1/12 md:w-1/12 sm:w-full flex items-center sm:items-center sm:pl-4">
            <div className="flex">
              <button
                type="button"
                className="bg-[#E99D00] text-white font-base rounded-lg px-6 py-2 w-full sm:w-auto flex items-center justify-center mx-1"
                // onClick={handleResetClick}
              >
                Reset
              </button>

              <button
                className={`bg-green-600 hover-bg-green-900 text-white py-2 px-4 w-full sm:w-auto rounded-lg flex items-center justify-center mx-1 `}
                type="submit"
                // disabled={loading}
              >
                {/* {loading ? (
                  <div className="flex items-center">
                    <div className="animate-spin mr-2 h-5 w-5 border-t-2 border-b-2 border-green-600 rounded-full"></div>
                    <span>Loading...</span>
                  </div>
                ) : ( */}
                <>
                  <FaSave className="mr-2" />
                  Save
                </>
                {/* )} */}
              </button>
            </div>
          </div>
        </div>
        {/* Input Field Start from here */}
        <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-3 gap-2 p-4 bg-white rounded-lg shadow-md mt-2">
          {inputConfigs.map((config, index) => (
            <div
              key={index}
              className={`lg:col-span-1 md:col-span-1 sm:col-span-1 flex flex-col mb-4 ${
                config.required ? "required-border" : ""
              }`}
            >
              <label className="text-sm font-semibold mb-1">
                {config.label}
                {config.required ? (
                  <span className="text-red-600">*</span>
                ) : null}
                :
              </label>
              {config.type === "select" ? (
                <select
                  className={`w-full h-10 rounded border ${"border-gray-300"} focus:outline-none focus:border-blue-500 px-3`}
                  id={config.id}
                  name={config.name}
                  value={formik.values[config.name]}
                  onChange={formik.handleChange}
                >
                  <option value="" disabled hidden>
                    {config.placeholder}
                  </option>
                  {config.options.map((option, optionIndex) => (
                    <option key={optionIndex} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  className={`w-full h-10 rounded border ${"border-gray-300"} focus:outline-none focus:border-blue-500 px-3`}
                  type={config.type}
                  id={config.id}
                  name={config.name}
                  placeholder={config.placeholder}
                  value={formik.values[config.name]}
                  onChange={formik.handleChange}
                  pattern={config.pattern}
                />
              )}
              {formik.touched[config.name] && formik.errors[config.name] && (
                <div className="text-red-500 text-xs mt-1">
                  {formik.errors[config.name]}
                </div>
              )}
            </div>
          ))}

          {/* END */}

          <div className="col-span-1 sm:col-span-1 mt-4 sm:mt-0">
            <label className="text-sm font-semibold mb-1">Ward:</label>
            <select
              id="ward"
              name="ward"
              className="w-full border p-2 mb-4"
              value={formik.values.ward}
              onChange={formik.handleChange}
            >
              <option value="">Select a Ward</option>
              {wardList.map((ward) => (
                <option key={ward.id} value={ward.name}>
                  {ward.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-span-1 sm:col-span-1 mt-4 sm:mt-0">
            <label className="text-sm font-semibold mb-1">Community:</label>
            <select
              id="community"
              name="community"
              className="w-full border p-2 mb-4"
              value={formik.values.community}
              onChange={formik.handleChange}
            >
              <option value="">Select Community</option>
              {communityList.map((community) => (
                <option key={community.id} value={community.name}>
                  {community.name}
                </option>
              ))}
            </select>
          </div>
          {/* Toggle Switch for is_admin */}

          
        </div>

        {/* Input Field End */}
      </form>
    </div>
  );
}
