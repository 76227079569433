import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiDetail } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "./Loader/Loader";
import HeaderTopBar from "./HeaderTopBar";
import { FaFileSignature } from "react-icons/fa";
import { useFormik } from "formik";

function CashTransfer() {
  const [loading, setLoading] = useState();
  const apiUrl = `${process.env.REACT_APP_MAIN_URL}/childs/conditional_cash`;
  const token = localStorage.getItem("token");
  const [cashTransferList, setCashTransferList] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const [filteredCashTransferList, setFilteredCashTransferList] = useState([]);
  const [isFilter, setIsFilter] = useState(false);

  const searchFormik = useFormik({
    initialValues: {
      customer: "",
      childID: "",
      amount: "",
      dateReceived: "",
      datePending: "",
      statusID: "",
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log("values", values);

      setIsFilter(true);
      const filteredData = cashTransferList.filter((cash) => {
        return (
          values.customer === 0 ||
          cash.caregiver?.firstname === values.customer ||
          values.childID === 0 ||
          cash.child?.childId === Number(values.childID) ||
          values.amount === 0 ||
          cash.amount === Number(values.amount) ||
          cash.nextDueDateStart === values.dateReceived ||
          cash.nextDueDateEnd === values.datePending ||
          cash.status === values.statusID
        );
      });

      // Update the officerList state with the filtered data
      setFilteredCashTransferList(filteredData);
      console.log("filteredData", filteredData);
    },
  });

  const handleClick = (val) => {
    const detailLink = `/caregiver-detail?caregiverId=${val?.caregiver?.careGiverId}&childID=${val?.child?.childId}&id=${val?.id}`;
    navigate(detailLink);
  };
  const [deleteUrl, setDeleteUrl] = useState(
    `${process.env.REACT_APP_MAIN_URL}/childs/conditional_cash`
  );

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Get Dashboard data
  const fetchData = () => {
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setCashTransferList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Delete function
  const handleDeleteClick = () => {
    const selectedIDs = selectedData.map((row) => row.id);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(deleteUrl, { conditional_cash_id: selectedIDs }, config)
      .then((response) => {
        toast.success("Deleted successfully");
        setSelectedData([]);
        fetchData();
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    if (searchTerm == "") {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  // const handleSearchClick = (e) => {
  //   setLoading(true);
  //   setSearchTerm(e.target.value);
  //   const filteredCashTransfers = cashTransferList.filter((cashTransfer) =>
  //     ["firstname", "nextDueDateStart", "status", "amount"].some((key) => {
  //       const value = cashTransfer[key];
  //       if (typeof value === "string" || typeof value === "number") {
  //         const lowerCaseSearchTerm = e.target.value.toLowerCase();
  //         const lowerCaseValue = String(value).toLowerCase();
  //         return lowerCaseValue.startsWith(lowerCaseSearchTerm);
  //       }
  //       return false;
  //     })
  //   );
  //   setCashTransferList(filteredCashTransfers);
  //   setLoading(false);
  // };
  const handleSearchClick = (e) => {
    setLoading(true);
    setSearchTerm(e.target.value);
    
    const filteredCashTransfers = cashTransferList.filter((cashTransfer) =>
      ["firstname", "nextDueDateStart", "status", "amount"].some((key) => {
        const value = key === "firstname" ? cashTransfer.caregiver.firstname : cashTransfer[key];
  
        if (typeof value === "string" || typeof value === "number") {
          const lowerCaseSearchTerm = e.target.value.toLowerCase();
          const lowerCaseValue = String(value).toLowerCase();
          return lowerCaseValue.startsWith(lowerCaseSearchTerm);
        }
  
        return false;
      })
    );
  
    setCashTransferList(filteredCashTransfers);
    setLoading(false);
  };
  

  // Handler for when the table header checkbox is clicked
  const handleTableHeaderCheckboxClick = () => {
    if (selectedData?.length === cashTransferList?.length) {
      setSelectedData([]);
    } else {
      setSelectedData(cashTransferList);
    }
  };

  //Pagination functionality
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const tableData = isFilter ? filteredCashTransferList : cashTransferList;
  const paginatedData = tableData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil(tableData?.length / itemsPerPage);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handler for when an individual checkbox is clicked
  const handleRowCheckboxClick = (rowData) => {
    if (selectedData.includes(rowData)) {
      setSelectedData(
        selectedData.filter((selectedRow) => selectedRow !== rowData)
      );
    } else {
      setSelectedData([...selectedData, rowData]);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <HeaderTopBar
        icon={<FaFileSignature className="text-gray-700 text-2xl mr-2" />}
        title={"Enrollment"}
        subtitle={"cash transfer"}
      />
      <br></br>
      <form onSubmit={searchFormik.handleSubmit}>
        <section className="container px-4 mx-auto">
          <div className="flex flex-col">
            {/* Filter start */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 bg-white p-2 rounded-sm">
              <div>
                <div className="grid grid-cols-1 md:grid-cols-4">
                  <label className="text-sm leading-7 text-blueGray-500 md:col-span-1 mt-4">
                    Customer Name:
                  </label>
                  <input
                    type="text"
                    id="customer"
                    name="customer"
                    placeholder="Cutomer Name"
                    onChange={searchFormik.handleChange}
                    value={searchFormik.values.customer}
                    className="md:col-span-3 w-full px-4 py-2 mt-2 text-sm text-black rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4">
                  <label className="text-sm leading-7 text-blueGray-500 md:col-span-1 mt-4">
                    Amount:
                  </label>
                  <input
                    type="text"
                    id="amount"
                    name="amount"
                    placeholder="Enter amount"
                    onChange={searchFormik.handleChange}
                    value={searchFormik.values.amount}
                    className="md:col-span-3 w-full px-4 py-2 mt-2 text-sm text-black rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                  />
                </div>
              </div>

              <div>
                <div className="grid grid-cols-1 md:grid-cols-4">
                  <label className="text-sm leading-7 text-blueGray-500 md:col-span-1 mt-4">
                    Status:
                  </label>
                  <input
                    type="text"
                    id="statusID"
                    name="statusID"
                    placeholder="Enter status"
                    onChange={searchFormik.handleChange}
                    value={searchFormik.values.statusID}
                    className="md:col-span-3 w-full px-4 py-2 mt-2 text-sm text-black rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                  />
                </div>
              </div>

              <div className="justify-center md:justify-end flex mt-2">
                <button
                  className=" bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </div>

            {/* Filter end */}

            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                  <div className="flex justify-between mb-1 p-2">
                    <button
                      type="button"
                      onClick={handleDeleteClick}
                      className="bg-red-500 text-white rounded px-4 py-2"
                    >
                      Delete
                    </button>
                    <div className="flex">
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchClick}
                        placeholder="Search..."
                        className="rounded-l border border-r-0 border-gray-300 dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700 px-4 py-2"
                      />
                    </div>
                  </div>
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead className="bg-gray-50 dark:bg-gray-800">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          <div className="flex items-center gap-x-3">
                            <input
                              type="checkbox"
                              className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"
                              onChange={handleTableHeaderCheckboxClick}
                              checked={
                                selectedData?.length ===
                                cashTransferList?.length
                              }
                            />

                            <button className="flex items-center gap-x-2">
                              <span>Details</span>

                              <svg
                                className="h-3"
                                viewBox="0 0 10 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z"
                                  fill="currentColor"
                                  stroke="currentColor"
                                  stroke-width="0.1"
                                />
                                <path
                                  d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z"
                                  fill="currentColor"
                                  stroke="currentColor"
                                  stroke-width="0.1"
                                />
                                <path
                                  d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z"
                                  fill="currentColor"
                                  stroke="currentColor"
                                  stroke-width="0.3"
                                />
                              </svg>
                            </button>
                          </div>
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          Date
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          Status
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          Customer
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          Purchase
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                      {paginatedData?.map((val, idx) => (
                        <tr key={idx}>
                          <td className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">
                            <div className="inline-flex items-center gap-x-3">
                              <input
                                type="checkbox"
                                className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"
                                onChange={() => handleRowCheckboxClick(val)}
                                checked={selectedData.includes(val)}
                              />
                              <span
                                onClick={() => {
                                  handleClick(val);
                                }}
                                className="cursor-pointer text-blue-500 hover:underline"
                              >
                                <BiDetail />
                              </span>
                            </div>
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                            {val?.nextDueDateStart}
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-700 whitespace-nowrap">
                            {val?.status}
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                            {val?.caregiver?.firstname}
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                            {val?.amount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {paginatedData?.length > 0 ? (
            <div className="flex items-center justify-center mt-8 space-x-4">
              {currentPage > 1 && (
                <button
                  type="button"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                >
                  Previous
                </button>
              )}

              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  type="button"
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`${
                    currentPage === index + 1
                      ? "bg-pink-600 text-white font-bold py-2 px-4 rounded"
                      : "bg-pink-200 hover:bg-pink-300 text-pink-800 font-bold py-2 px-4 rounded"
                  } focus:outline-none focus:ring focus:border-pink-300`}
                >
                  {index + 1}
                </button>
              ))}

              {currentPage < 1 ? (
                <button
                  type="button"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled
                  className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                >
                  Next
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                >
                  Next
                </button>
              )}
            </div>
          ) : (
            <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
              No Data Found
            </div>
          )}
        </section>
      </form>
    </div>
  );
}

export default CashTransfer;
