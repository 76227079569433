import React, { useState, useEffect } from "react";
import { Outlet,useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import axios from "axios";

export default function Layout() {
  const navigate = useNavigate();
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const isLargeDevice = window.innerWidth >= 1024;
    setIsSidebarOpen(isLargeDevice);
    const handleResize = () => {
      const isLargeDevice = window.innerWidth >= 1024;
      setIsSidebarOpen(isLargeDevice);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const setupAxiosInterceptors = () => {
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (res) {
        return res;
      },
      (error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          redirectToLogin(); // Redirect to the login page
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    );
  };

  const redirectToLogin = () => {
    navigate("/login");
    localStorage.clear();
  };

  useEffect(() => {
    setupAxiosInterceptors();
  }, []);

  return (
    <div className="bg-gray-200">
      <button
        className={`md:hidden bg-pink-400 rounded-3xl fixed top-2 left-2 z-10 p-2 text-black mb-8`}
        onClick={toggleSidebar}
      >
        ☰
      </button>

      <div className="relative">
        {(isSidebarOpen || window.innerWidth >= 1024) && (
          <div className="w-60 bg-neutral-100 h-screen fixed left-0 top-0">
            <Sidebar />
          </div>
        )}
        <div
          className={`${
            isSidebarOpen && window.innerWidth >= 1024 ? "md:ml-60" : "md:ml-0"
          } transition-all duration-300 ease-in-out p-4 right-0 left-0 top-0`}
        >
          {window.innerWidth >= 1024 && <Header />}
          <Outlet />
        </div>
      </div>
    </div>
  );
}
