import React from "react";

const HeaderTopBar = ({ icon, title, subtitle }) => {
  return (
    <div className="mt-16 w-full h-14 bg-white rounded-2xl flex items-center p-4 text-gray-700">
      {icon}
      <div className="flex">
        <p className="text-2xl font-bold flex ">
          {title}
          <span className="text-sm bottom-0  pl-2 mt-2">
            /<span className="ml-1">{subtitle}</span>
          </span>
        </p>
      </div>
    </div>
  );
};
export default HeaderTopBar;
