import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"; 
import Layout from "./components/shared/Layout";
import Dashboard from "./components/Dashboard";
import Reminder from "./components/Reminder";
import CashTransfer from "./components/CashTransfer";
import EnrollmentOfficer from "./components/pages/enrollment/EnrollmentOfficer";
import CaregiverDetail from "./components/pages/caregiver/CaregiverDetail";
import CaregiverMain from "./components/pages/caregiver/CaregiverMain";
import CaregiverChild from "./components/pages/caregiver/CaregiverChild";
import Login from "./components/pages/auth/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChildHistory from "./components/pages/caregiver/ChildHistory";
import OfficerDetail from "./components/pages/caregiver/OfficerDetail";
import AddTransaction from "./components/pages/caregiver/AddTransaction";
import ReminderDetails from "./components/pages/caregiver/ReminderDetails";
import EnrollmentOfficerUsernamePass from "./components/pages/enrollment/EnrollmentOfficerUsernamePass";
import Addministration from "./components/Administration";
import EnrollmentHealthFacility from "./components/pages/enrollment/EnrollmentHealthFacility";
import HealthFacilityDetail from "./components/pages/enrollment/HealthFacilityDetail";
import EditHealthFacility from "./components/pages/enrollment/EditHealthFacility";
import IncentiveChild from "./components/IncentiveChild";
import CheckingDetails from "./components/CheckingDetails";
import EnrollmentChild from "./components/pages/enrollment/EnrollmentChild";
import EnrollmentChildDetail from "./components/pages/enrollment/EnrollmentChildDetail";
import DefaulterTracking from "./components/pages/DefaulterTracking";
import IncentiveHealthFacility from "./components/IncentiveHealthFacility";
import IncentiveHealthFacilityDetail from "./components/pages/enrollment/IncentiveHealthFacilityDetail";
import AddministrationNigeria from "./components/AddministrationNigeria";
import AddministrationCameron from "./components/AddministrationCameron";
import AddministrationDrc from "./components/AddministrationDrc";
import AddministrationLesotho from "./components/AddministrationLesotho";

function App() {
  const token = localStorage.getItem("token");
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        {token ? (
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="/transfer" element={<CashTransfer />} />
            <Route path="/reminder" element={<Reminder />} />
            <Route path="/emrollment/child" element={<EnrollmentChild />} />
            <Route path="/enrollment/child/detail" element={<EnrollmentChildDetail />} />
            <Route path="/emrollment/officer" element={<EnrollmentOfficer />} />
            <Route path="/caregiver-detail" element={<CaregiverDetail />} />
            <Route path="/caregiver" element={<CaregiverMain />} />
            <Route path="/officer-detail" element={<OfficerDetail />} />
            <Route path="/child" element={<CaregiverChild />} />
            <Route path="/child/history" element={<ChildHistory />} />
            <Route path="/add-transaction" element={<AddTransaction />} />
            <Route path="/reminder-details" element={<ReminderDetails />} />
            <Route path="/administration/Nigeria" element={<AddministrationNigeria />} />
            <Route path="/administration/Cameron" element={<AddministrationCameron />} />
            <Route path="/administration/DRC" element={<AddministrationDrc />} />
            <Route path="/administration/Lesotho" element={<AddministrationLesotho />} />
            <Route path="/defaulter/tracking" element={<DefaulterTracking />} />
            <Route path="/incentive/child" element={<IncentiveChild />} />
            <Route path="/emrollment/incentive-health-facility" element={<IncentiveHealthFacility />} />
            <Route path="/checking-details" element={<CheckingDetails />} />
            <Route
              path="/enrollment-username-and-password"
              element={<EnrollmentOfficerUsernamePass />}
            />
            <Route
              path="/edit-health-facility"
              element={<EditHealthFacility />}
            />
            <Route
              path="/emrollment/health-facility"
              element={<EnrollmentHealthFacility />}
            />
            <Route
              path="/health-facility-detail"
              element={<HealthFacilityDetail />}
            />
            <Route
              path="/incentive-health-facility-detail"
              element={<IncentiveHealthFacilityDetail />}
            />
          </Route>
        ) : (
          <Route path="/" element={<Navigate to="/login" />} />
        )}
      </Routes>
      <ToastContainer position="top-right" autoClose={3000} />
    </Router>
  );
}

export default App;
