import React, { useEffect, useState } from "react";
import { FaFileExcel, FaFileSignature } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BiDetail } from "react-icons/bi";
import { useFormik } from "formik";
import * as XLSX from "xlsx";
import HeaderTopBar from "./HeaderTopBar";
import Loader from "./Loader/Loader";

export default function IncentiveHealthFacility() {
  const [loading, setLoading] = useState();
  const apiUrl = `${process.env.REACT_APP_MAIN_URL}/dashboard/incentive_disbursement_hf`;
  const token = localStorage.getItem("token");
  const [officerList, setOfficerList] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [lgaList, setLgaList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [filterDataList, setFilterDataList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [devisionList, setDevisionList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [constituencyList, setConstituencyList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const navigate = useNavigate();
  const handleClick = (id) => {
    const detailLink = `/incentive-health-facility-detail?id=${id}`;
    navigate(detailLink);
  };
  const [deleteUrl, setDeleteUrl] = useState(
    `${process.env.REACT_APP_MAIN_URL}/dashboard/incentive_disbursement_hf`
  );

  const searchFormik = useFormik({
    initialValues: {
      careGiverId: "",
      dob: "",
      lga: "",
      provience: "",
      eoID: "",
      country: "",
      region: "",
      district: "",
      enrolledDate: "",
      state: "",
      division: "",
      constituency: "",
      ward:"",
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      setIsFilter(true);
      const filteredData = officerList.filter((officer) => {
        const enrollmentIdMatch =
          values?.eoID &&
          officer.hf?.enrollment_officer === Number(values?.eoID);
        const dateEnrolledMatch =
          values?.enrolledDate && officer.hf?.date_enrolled === values?.enrolledDate;
        const lgaMatch = values?.lga && officer.lga === values?.lga;
        const wardMatch = values?.ward && officer?.hf?.ward === values?.ward;

        const hfIdMatch =
          values?.careGiverId &&
          officer.hf?.qrcodeid === values?.careGiverId;

        return (
          enrollmentIdMatch ||
          dateEnrolledMatch ||
          lgaMatch ||
          wardMatch || hfIdMatch
        );
      });
      setFilterDataList(filteredData);
    },
  });


  const wardUrl = `${process.env.REACT_APP_MAIN_URL}/basic/admin_ward/`;
  const countryUrl = `${process.env.REACT_APP_MAIN_URL}/basic/country`;
  const stateUrl = `${process.env.REACT_APP_MAIN_URL}/basic/create_state_for_admin`;
  const regionUrl = `${process.env.REACT_APP_MAIN_URL}/basic/create_region_for_admin`;
  const devisionUrl = `${process.env.REACT_APP_MAIN_URL}/basic/create_division_for_admin`;
  const provinceUrl = `${process.env.REACT_APP_MAIN_URL}/basic/create_province_for_admin`;
  const districtUrl = `${process.env.REACT_APP_MAIN_URL}/basic/create_district_for_admin`;
  const constituencyUrl = `${process.env.REACT_APP_MAIN_URL}/basic/admin_constituency/`;

  const getCountry = () => {
    setLoading(true);
    axios
      .get(countryUrl, axiosConfig)
      .then((response) => {
        setCountryList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };
  const getConstituency = () => {
    setLoading(true);
    axios
      .get(constituencyUrl, axiosConfig)
      .then((response) => {
        setConstituencyList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };
  const getDistrict = () => {
    setLoading(true);
    axios
      .get(districtUrl, axiosConfig)
      .then((response) => {
        setDistrictList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };
  const getState = () => {
    setLoading(true);
    axios
      .get(stateUrl, axiosConfig)
      .then((response) => {
        setStateList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };
  const getRegion = () => {
    setLoading(true);
    axios
      .get(regionUrl, axiosConfig)
      .then((response) => {
        setRegionList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };
  const getDivision = () => {
    setLoading(true);
    axios
      .get(devisionUrl, axiosConfig)
      .then((response) => {
        setDevisionList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };
  const getProvince = () => {
    setLoading(true);
    axios
      .get(provinceUrl, axiosConfig)
      .then((response) => {
        setProvinceList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };
  const getLga = (id) => {
    const lgaUrl = `${process.env.REACT_APP_MAIN_URL}/basic/get_lga_by_state/${id}`;
    setLoading(true);
    axios
      .get(lgaUrl, axiosConfig)
      .then((response) => {
        setLgaList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };
  //Get Ward
  const getWard = () => {
    setLoading(true);
    axios
      .get(wardUrl, axiosConfig)
      .then((response) => {
        setWardList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in ward");
        setLoading(false);
      });
  };

  useEffect(() => {
    // getLga();
    getWard();
    getCountry();
    getState();
    getRegion();
    getDivision();
    getProvince();
    getConstituency();
    getDistrict();
  }, []);
  useEffect(() => {
    if (searchFormik?.values?.state) {
      getLga(searchFormik?.values?.state);
    }
  }, [searchFormik?.values?.state]);

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Get Dashboard data
  const fetchData = () => {
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setOfficerList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  //Delete function
  const handleDeleteClick = () => {
    const selectedIDs = selectedData.map((row) => row.id);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(deleteUrl, { id: selectedIDs }, config)
      .then((response) => {
        toast.success("Deleted successfully");
        setSelectedData([]);
        fetchData();
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    if (searchTerm == "") {
      fetchData();
    }
  }, [searchTerm]);

  // const handleSearchClick = (e) => {
  //   setLoading(true);
  //   setSearchTerm(e.target.value);
  //   const filteredOfficers = officerList.filter((officer) =>
  //     ["firstname", "lastname", "username", "dateOfBirth", "ward", "lga"].some(
  //       (key) => {
  //         const value = officer[key];
  //         if (typeof value === "string" || typeof value === "number") {
  //           const lowerCaseSearchTerm = e.target.value.toLowerCase();
  //           const lowerCaseValue = String(value).toLowerCase();
  //           return lowerCaseValue.startsWith(lowerCaseSearchTerm);
  //         }
  //         return false;
  //       }
  //     )
  //   );
  //   setOfficerList(filteredOfficers);
  //   setLoading(false);
  // };
  const handleSearchClick = (e) => {
    setIsFilter(false);
    setLoading(true);
    setSearchTerm(e.target.value);
    const filteredHF = officerList.filter((reminder) =>
      [
        "id",
        "enrollment_officer",
        "date_enrolled",
        "name",
        "type_of_hf",
        "geolocation",
        "ward",
        "community",
      ].some((key) => {
        const value =
          key === "id"
            ? reminder.hf?.qrcodeid
            : key === "enrollment_officer"
            ? reminder?.hf?.enrollment_officer
            : key === "date_enrolled"
            ? reminder?.hf?.date_enrolled
            : key === "name"
            ? reminder?.hf?.name
            : key === "type_of_hf"
            ? reminder?.hf?.type_of_hf
            : key === "geolocation"
            ? reminder?.hf?.geolocation
            : key === "ward"
            ? reminder?.hf?.ward
            : key === "community"
            ? reminder?.hf?.community
            : reminder[key];
        if (typeof value === "string" || typeof value === "number") {
          const lowerCaseSearchTerm = e.target.value.toLowerCase();
          const lowerCaseValue = String(value).toLowerCase();
          return lowerCaseValue.startsWith(lowerCaseSearchTerm);
        }
        return false;
      })
    );
    setOfficerList(filteredHF);
    setLoading(false);
  };

  // Handler for when the table header checkbox is clicked
  const handleTableHeaderCheckboxClick = () => {
    if (selectedData?.length === officerList?.length) {
      setSelectedData([]);
    } else {
      setSelectedData(officerList);
    }
  };

  //Pagination functionality
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const tableData = isFilter ? filterDataList : officerList;
  const paginatedData = tableData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil(tableData?.length / itemsPerPage);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handler for when an individual checkbox is clicked
  const handleRowCheckboxClick = (rowData) => {
    if (selectedData.includes(rowData)) {
      setSelectedData(
        selectedData.filter((selectedRow) => selectedRow !== rowData)
      );
    } else {
      setSelectedData([...selectedData, rowData]);
    }
  };

  const handleNewModalClose = () => {
    setShowModal(false);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      password: "",
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      handleNewModalClose();
    },
  });

  const modalOverlayClass = showModal
    ? "fixed inset-0 flex items-center justify-center z-50 bg-opacity-75 backdrop-blur-sm"
    : "";

  //Download Excell file function
  const handleDownloadExcel = () => {
    let export_data = isFilter ? filterDataList : officerList;
    let final_data = export_data.map((d) => d.hf?.qrcodeid);
    for(let i=0;i<export_data.length;i++){
      delete export_data[i].hf;
      export_data[i].hf = final_data[i];
    }
    const ws = XLSX.utils.json_to_sheet(export_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    const arrayBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert array buffer to a Blob
    const blob = new Blob([arrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "tableData.xlsx";
    link.click();
  };
  return (
    <div>
      <HeaderTopBar
        icon={<FaFileSignature className="text-gray-700 text-2xl mr-2" />}
        title={"INCENTIVE DISBURSE"}
        subtitle={"Health Facility"}
      />
      {loading && <Loader />}

      <form onSubmit={searchFormik.handleSubmit}>
        <div className="w-full mt-2">
          <section className="container px-4 mx-auto">
            <div className="flex flex-col">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-6 bg-white rounded-lg shadow-md lg:grid-cols-5">
                <div className="col-span-4 p-2">
                  <div className="grid grid-cols-8 text-left">
                    <label className=" text-gray-700 col-span-1 flex items-center text-xs ml-2">
                      HF ID:
                    </label>
                    <div className="col-span-1 mt-1">
                      <input
                        type="text"
                        id="careGiverId"
                        name="careGiverId"
                        placeholder="Enter ID"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.careGiverId}
                        className="w-full border rounded-lg focus:outline-none text-xs focus:border-blue-500 bg-gray-100 hover:border-gray-400 px-1 py-1"
                      />
                    </div>
                    <label className="text-gray-700 col-span-1 flex items-center text-xs ml-8">
                      Country:
                    </label>
                    <div className="col-span-1">
                      <select
                        className="w-full px-5 py-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-gray-100 hover:border-gray-400 text-xs mr-2 mt-1"
                        id="country"
                        name="country"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.country?.id}
                      >
                        <option value=""></option>
                        {countryList.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <label className="text-gray-700 col-span-1 flex items-center text-xs ml-12">
                      LGA:
                    </label>
                    <div className="col-span-1">
                      <select
                        className="w-full px-5 py-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-gray-100 hover:border-gray-400 text-xs mr-2 mt-1"
                        id="lga"
                        name="lga"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.lga?.id}
                      >
                        <option value=""></option>
                        {lgaList.map((lga) => (
                          <option key={lga.id} value={lga.id}>
                            {lga.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <label className="text-gray-700 col-span-1 flex items-center text-xs ml-8">
                      Provience:
                    </label>
                    <div className="col-span-1">
                      <select
                        className="w-full px-5 py-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-gray-100 hover:border-gray-400 text-xs mr-2 mt-1"
                        id="provience"
                        name="provience"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.provience?.id}
                      >
                        <option value=""></option>
                        {provinceList.map((provience) => (
                          <option key={provience.id} value={provience.id}>
                            {provience.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <label className=" text-gray-700 col-span-1 flex items-center text-xs ml-2">
                      EO ID:
                    </label>
                    <div className="col-span-1 mt-1">
                      <input
                        type="text"
                        id="eoID"
                        name="eoID"
                        placeholder="Enter ID"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.eoID}
                        className="w-full border rounded-lg focus:outline-none text-xs focus:border-blue-500 bg-gray-100 hover:border-gray-400 px-1 py-1"
                      />
                    </div>
                    <label className="text-gray-700 col-span-1 flex items-center text-xs ml-8">
                      Ward:
                    </label>
                    <div className="col-span-1">
                      <select
                        className="w-full px-5 py-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-gray-100 hover:border-gray-400 text-xs mr-2 mt-1"
                        id="ward"
                        name="ward"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.ward?.name}
                      >
                        <option value=""></option>
                        {wardList.map((ward) => (
                          <option key={ward.id} value={ward.name}>
                            {ward.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <label className="text-gray-700 col-span-1 flex items-center text-xs ml-8">
                      Region:
                    </label>
                    <div className="col-span-1">
                      <select
                        className="w-full px-5 py-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-gray-100 hover:border-gray-400 text-xs mr-2 mt-1"
                        id="region"
                        name="region"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.region?.id}
                      >
                        <option value=""></option>
                        {regionList.map((region) => (
                          <option key={region.id} value={region.id}>
                            {region.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <label className="text-gray-700 col-span-1 flex items-center text-xs ml-8">
                      District:
                    </label>
                    <div className="col-span-1">
                      <select
                        className="w-full px-5 py-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-gray-100 hover:border-gray-400 text-xs mr-2 mt-1"
                        id="district"
                        name="district"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.district?.id}
                      >
                        <option value=""></option>
                        {districtList.map((district) => (
                          <option key={district.id} value={district.id}>
                            {district.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <label className=" text-gray-700 col-span-1 flex items-center text-xs ml-2">
                      Date Enrolled:
                    </label>
                    <div className="col-span-1 mt-1">
                      <input
                        type="date"
                        id="enrolledDate"
                        name="enrolledDate"
                        placeholder="Enter ID"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.enrolledDate}
                        className="w-full border rounded-lg focus:outline-none text-xs focus:border-blue-500 bg-gray-100 hover:border-gray-400 px-1 py-1"
                      />
                    </div>
                    <label className="text-gray-700 col-span-1 flex items-center text-xs ml-8">
                      State:
                    </label>
                    <div className="col-span-1">
                      <select
                        className="w-full px-5 py-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-gray-100 hover:border-gray-400 text-xs mr-2 mt-1"
                        id="state"
                        name="state"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.state?.id}
                      >
                        <option value=""></option>
                        {stateList.map((state) => (
                          <option key={state.id} value={state.id}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <label className="text-gray-700 col-span-1 flex items-center text-xs ml-8">
                      Division:
                    </label>
                    <div className="col-span-1">
                      <select
                        className="w-full px-5 py-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-gray-100 hover:border-gray-400 text-xs mr-2 mt-1"
                        id="division"
                        name="division"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.division?.id}
                      >
                        <option value=""></option>
                        {devisionList.map((division) => (
                          <option key={division.id} value={division.id}>
                            {division.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <label className="text-gray-700 col-span-1 flex items-center text-xs ml-5">
                      Constituency:
                    </label>
                    <div className="col-span-1">
                      <select
                        className="w-full px-5 py-1 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-gray-100 hover:border-gray-400 text-xs mr-2 mt-1"
                        id="constituency"
                        name="constituency"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.constituency?.id}
                      >
                        <option value=""></option>
                        {constituencyList.map((constituency) => (
                          <option key={constituency.id} value={constituency.id}>
                            {constituency.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className=" col-span-1 mt-4">
                  <button
                    className=" bg-blue-500 text-white px-3 py-2 text-xs rounded-lg hover:bg-blue-600 transition duration-300"
                    type="submit"
                  >
                    Filter
                  </button>
                  <button
                    className=" bg-white-500 text-black px-3 py-2 text-xs rounded-lg hover:bg-red-600 transition duration-300"
                    type="button" onClick={() => setIsFilter(false)}
                  >
                    Reset
                  </button>
                </div>
              </div>
              {/* </form> */}

              {/* Filter end */}

              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                    <div className="flex justify-between mb-1 p-2">
                      <button
                        type="button"
                        onClick={() => setShowDeleteModal(true)}
                        disabled={selectedData.length > 0 ? false : true}
                        className="bg-red-500 text-white rounded px-4 py-2"
                      >
                        Delete
                      </button>
                      <div className="flex">
                        <button
                          onClick={handleDownloadExcel}
                          type="button"
                          className="text-green-500 bg-white rounded px-2 py-1 flex items-center mr-2"
                        >
                          <FaFileExcel size={20} className="" />{" "}
                        </button>

                        <input
                          type="text"
                          value={searchTerm}
                          onChange={handleSearchClick}
                          placeholder="Search..."
                          className="border border-gray-300 dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700 px-4 py-2"
                        />
                      </div>
                    </div>

                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-gray-50 dark:bg-gray-800">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            <div className="flex items-center gap-x-3">
                              <input
                                type="checkbox"
                                className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"
                                onChange={handleTableHeaderCheckboxClick}
                                checked={
                                  selectedData?.length === officerList?.length
                                }
                              />

                              <button className="flex items-center gap-x-2">
                                <span>Details</span>

                                <svg
                                  className="h-3"
                                  viewBox="0 0 10 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z"
                                    fill="currentColor"
                                    stroke="currentColor"
                                    stroke-width="0.1"
                                  />
                                  <path
                                    d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z"
                                    fill="currentColor"
                                    stroke="currentColor"
                                    stroke-width="0.1"
                                  />
                                  <path
                                    d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z"
                                    fill="currentColor"
                                    stroke="currentColor"
                                    stroke-width="0.3"
                                  />
                                </svg>
                              </button>
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            HF ID
                          </th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            EO ID
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Date Enrolled
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            In-Charge Officer Name
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            HF Type
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Geolocation
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Ward
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Community
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                        {paginatedData?.map((val, idx) => (
                          <tr key={idx}>
                            <td className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">
                              <div className="inline-flex items-center gap-x-3">
                                <input
                                  type="checkbox"
                                  className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"
                                  onChange={() => handleRowCheckboxClick(val)}
                                  checked={selectedData.includes(val)}
                                />
                                <span
                                  onClick={() => {
                                    handleClick(val?.id);
                                  }}
                                  className="cursor-pointer text-blue-500 hover:underline"
                                >
                                  <BiDetail />
                                </span>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-700 whitespace-nowrap">
                              {val?.hf?.qrcodeid}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.hf?.enrollment_officer}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.hf?.date_enrolled}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.hf?.name}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.hf?.type_of_hf}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap ">
                              {val?.hf?.geolocation}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap ">
                              {val?.hf?.ward}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap ">
                              {val?.hf?.community}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {paginatedData?.length > 0 ? (
              <div className="flex items-center justify-center mt-8 space-x-4">
                {currentPage > 1 && (
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Previous
                  </button>
                )}

                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={`${
                      currentPage === index + 1
                        ? "bg-pink-600 text-white font-bold py-2 px-4 rounded"
                        : "bg-pink-200 hover:bg-pink-300 text-pink-800 font-bold py-2 px-4 rounded"
                    } focus:outline-none focus:ring focus:border-pink-300`}
                  >
                    {index + 1}
                  </button>
                ))}

                {currentPage < 1 ? (
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled
                    className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Next
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
                No Data Found
              </div>
            )}
          </section>
        </div>
      </form>
      <div className={modalOverlayClass}>
        {showDeleteModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-75 backdrop-blur-lg">
            <div className="w-full max-w-md p-6 mx-4 bg-white rounded-lg shadow-md">
              <h2 className="text-2xl font-bold text-blue-600 mb-4">
                Delete Confirmation
              </h2>
              <form >
                <div className="mb-4">
                  <label
                    htmlFor="firstName"
                    className="text-gray-700 font-medium mb-2"
                  >
                    Do you want to delete this records?
                  </label>
                </div>
                
                <div className="mt-6">
                  <button
                    type="submit"
                    onClick={handleDeleteClick}
                    className="bg-red-500 text-white rounded px-4 py-2 hover:bg-red-600 focus:outline-none"
                  >
                    Confirm
                  </button>
                  <button
                    onClick={() => setShowDeleteModal(false)}
                    className="bg-gray-300 text-gray-700 rounded px-4 py-2 ml-4 hover:bg-gray-400 focus:outline-none"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-75 backdrop-blur-lg">
            <div className="w-full max-w-md p-6 mx-4 bg-white rounded-lg shadow-md">
              <h2 className="text-2xl font-bold text-blue-600 mb-4">
                Add New User
              </h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="firstName"
                    className="text-gray-700 font-medium mb-2"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="lastName"
                    className="text-gray-700 font-medium mb-2"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="userName"
                    className="text-gray-700 font-medium mb-2"
                  >
                    User Name
                  </label>
                  <input
                    type="text"
                    id="userName"
                    name="userName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userName}
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="text-gray-700 font-medium mb-2"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="password"
                    className="text-gray-700 font-medium mb-2"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
                  />
                </div>
                <div className="mt-6">
                  <button
                    type="submit"
                    className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600 focus:outline-none"
                  >
                    Save
                  </button>
                  <button
                    onClick={handleNewModalClose}
                    className="bg-gray-300 text-gray-700 rounded px-4 py-2 ml-4 hover:bg-gray-400 focus:outline-none"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
